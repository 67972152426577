import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function InstructorTableComponent({ thead, tbody }) {
  const { t } = useContext(TranslatorContext);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const openEditPage = (id) => {
    navigate(`/instructor/${id}`);
  };

  const onToggleActive = async (id, currentStatus) => {
    try {
      const response = await Api.toggleInstructor(id);

      if (!response.data) {
        throw new Error("Network response was not ok");
      }

      setData((prevData) =>
        prevData.map((item) =>
          item._id === id ? { ...item, active: !currentStatus } : item
        )
      );
    } catch (error) {
      console.error("Error updating active status:", error);
    }
  };

  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th className="text-center" key={index}>{item}</th>
            ))}
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <tr key={index}>
              <td title="Name">{item.name}</td>
              {/* <td title="Designation">{item.designation}</td> */}
              <td>
                <div className="mc-table-action">
                  <ButtonComponent
                    title={t("Edit")}
                    className="material-icons edit"
                    onClick={() => openEditPage(item._id)}
                  >
                    edit
                  </ButtonComponent>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.active}
                        onChange={() => onToggleActive(item._id, item.active)}
                        name="activeSwitch"
                        color="primary"
                      />
                    }
                    label={item.active ? t("Active") : t("Inactive")}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
