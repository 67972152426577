import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Modal, Form, Spinner } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";



export default function TestimonialTableComponent({ tbody }) {
  const { t } = useContext(TranslatorContext);

  const [data, setData] = useState([]);
  const [testimonialData, setTestimonialData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const openEditModal = (item) => {
    setTestimonialData(item);
    setEditModal(true);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    setIsEditLoading(true);

    try {
      

      const response = await Api.updateTestimonialById(testimonialData,testimonialData._id)
      console.log(response)
      if (!response.data) {
        throw new Error("Network response was not ok");
      }

      const updatedTestimonial = response.data

      setData((prevData) =>
        prevData.map((item) =>
          item._id === updatedTestimonial._id ? updatedTestimonial : item
        )
      );
      setEditModal(false);
    } catch (error) {
      console.log("Error updating item:", error);
    } finally {
      setIsEditLoading(false);
    }
  };

  const openDeleteModal = (item) => {
    setTestimonialData(item);
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      
      const response = await Api.deleteTestimonialById(testimonialData._id)
      console.log(response);
      if (response.status === 204) {
        setData((prevData) =>
          prevData.filter((item) => item._id !== testimonialData._id)
        );
        setDeleteModal(false);
      }else{
        const errorMessage = await response.text();
        console.log(`Error deleting item: ${response.status} - ${errorMessage}`);
        throw new Error("Network response was not ok");
      }

      
    } catch (error) {
      console.log("Error deleting item:", error);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            <th className="text-center">{t('title')}</th>
            <th className="text-center">{t('stars')}</th>
            <th className="text-center">{t('content')}</th>
            <th className="text-center">{t('name')}</th>
            <th className="text-center">{t('position')}</th>
            <th className="text-center">{t('actions')}</th>
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <tr key={index}>
              <td title={item.title}>{item.title}</td>
              <td title={item.content}>{item.content}</td>
              <td title={item.stars}>{item.stars}</td>
              <td title={item.name}>{item.name}</td>
              <td title={item.position}>{item.position}</td>
              <td>
                <div className="mc-table-action">
                  <ButtonComponent
                    title={t('edit')}
                    className="material-icons edit"
                    onClick={() => openEditModal(item)}
                  >
                    edit
                  </ButtonComponent>
                  <ButtonComponent
                    title={t('delete')}
                    className="material-icons delete"
                    onClick={() => openDeleteModal(item)}
                  >
                    delete
                  </ButtonComponent>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={editModal} onHide={() => setEditModal(false)}>
        <Form onSubmit={handleEdit}>
          <div className="mc-user-modal">
            <Form.Group className="form-group inline mb-4 mt-3">
              <Form.Label>{t("title")}</Form.Label>
              <Form.Control
                type="text"
                value={testimonialData?.title || ""}
                onChange={(e) =>
                  setTestimonialData({ ...testimonialData, title: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="form-group inline mb-4">
              <Form.Label>{t("content")}</Form.Label>
              <Form.Control
                as="textarea"
                value={testimonialData?.content || ""}
                onChange={(e) =>
                  setTestimonialData({ ...testimonialData, content: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="form-group inline mb-4">
              <Form.Label>{t("stars")}</Form.Label>
              <Form.Control
                type="number"
                min="1"
                max="5"
                value={testimonialData?.stars || ""}
                onChange={(e) =>
                  setTestimonialData({ ...testimonialData, stars: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="form-group inline mb-4">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                value={testimonialData?.name || ""}
                onChange={(e) =>
                  setTestimonialData({ ...testimonialData, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="form-group inline mb-4">
              <Form.Label>{t("position")}</Form.Label>
              <Form.Control
                type="text"
                value={testimonialData?.position || ""}
                onChange={(e) =>
                  setTestimonialData({ ...testimonialData, position: e.target.value })
                }
              />
            </Form.Group>
            <Modal.Footer>
              <ButtonComponent
                type="button"
                className="btn btn-secondary"
                onClick={() => setEditModal(false)}
              >
                {t("close_popup")}
              </ButtonComponent>
              <ButtonComponent
                type="submit"
                className="btn btn-success"
                disabled={isEditLoading}
              >
                {isEditLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  t("save_changes")
                )}
              </ButtonComponent>
            </Modal.Footer>
          </div>
        </Form>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <div className="mc-alert-modal">
        <i className="material-icons">new_releases</i>
          <h3>{t("are you sure")}</h3>
          <p>{t("want to delete item")}</p>
          <Modal.Footer>
            <ButtonComponent
              type="button"
              className="btn btn-secondary"
              onClick={() => setDeleteModal(false)}
            >
              {t("close")}
            </ButtonComponent>
            <ButtonComponent
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
              disabled={isDeleteLoading}
            >
              {isDeleteLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                t("delete")
              )}
            </ButtonComponent>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}
