import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Modal, Form, Spinner, Alert } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router-dom for routing
import Api from "../../api/baseUrl";

export default function FaqTableComponent({ thead, tbody, onUpdate }) {
  const { t } = useContext(TranslatorContext);
  const [data, setData] = useState([]);
  const [currentFaq, setCurrentFaq] = useState({});
  const [questionModal, setQuestionModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState({ question: "", answer: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (Array.isArray(tbody)) {
      setData(tbody);
    }
  }, [tbody]);

  const openQuestionModal = (faq) => {
    setCurrentFaq(faq);
    setNewQuestion({ question: "", answer: "" });
    setQuestionModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuestionSave = async () => {
    try {
      const updatedItems = [...currentFaq.items, newQuestion];

      await Api.updateFaq(currentFaq._id, { items: updatedItems });
      onUpdate();
      setQuestionModal(false);
    } catch (error) {
      setError("Error saving question: " + error.message);
    }
  };

  const redirectToEditPage = (category) => {
    navigate(`/editFaqPage/${category}`);
  };

  return (
    <div className="mc-table-responsive">
      {error && <Alert variant="danger">{error}</Alert>}
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr className="text-center">
            {thead.map((heading, index) => (
              <th className="text-center" key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even text-center">
          {Array.isArray(data) &&
            data.map((faq, index) => (
              <tr key={index}>
                <td className="text-center">{faq.headerText}</td>
                <td className="text-center">{faq.category}</td>
                <td className="text-center">
                <div className="mc-table-action flex justify-content-center align-items-center">
                  {faq.items.length}
                  <ButtonComponent
                    title="Add Question"
                    className="material-icons add"
                    onClick={() => openQuestionModal(faq)}
                    style={{ margin: "1px 10px", color: "#1866EF" }}
                  >
                    add
                  </ButtonComponent>
                  </div>
                </td>
                <td className="text-center">
                <div className="mc-table-action flex justify-content-center">
                  <ButtonComponent
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => redirectToEditPage(faq.category)}
                    // style={{ margin: "0 10px", color: "green" }}
                  >
                    edit
                  </ButtonComponent>
                  </div>
                </td>
               
              </tr>
            ))}
        </tbody>
      </table>

      <Modal show={questionModal} onHide={() => setQuestionModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add New Question")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleQuestionSave}>
            <Form.Group className="mb-3" controlId="formQuestion">
              <Form.Label>{t("Question")}</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={newQuestion.question}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAnswer">
              <Form.Label>{t("Answer")}</Form.Label>
              <Form.Control
                as="textarea"
                name="answer"
                value={newQuestion.answer}
                onChange={handleInputChange}
              />
            </Form.Group>
            <ButtonComponent
              type="submit"
              className="primary"
            >
              {t("Add Question")}
            </ButtonComponent>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
