const ImageUrl = (url) => {
    if (!url) return '';  // Add a null check here to prevent errors
    console.log(url)
    if (url.startsWith('https')) {
        return url;
    } else {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
        const videoExtensions = ['mp4', 'mov', 'avi', 'flv', 'wmv', 'mkv'];
        const extension = url.split('.').pop().toLowerCase();

        if (imageExtensions.includes(extension)) {
            return 'https://res.cloudinary.com/di1aeckib/image/upload/' + url;
        } else if (videoExtensions.includes(extension)) {
            return 'https://res.cloudinary.com/di1aeckib/video/upload/' + url;
        } else {
            return 'https://res.cloudinary.com/di1aeckib/image/upload/' + url; 
        }
    }
}

export default ImageUrl;
