import React, { useContext, useState, useEffect } from "react";
import { TranslatorContext } from "../../context/Translator";
import { ButtonComponent, AnchorComponent } from "../../components/elements";
import IconFieldComponent from "../../components/fields/IconFieldComponent";
import LogoComponent from "../../components/LogoComponent";
import Api from "../../api/baseUrl"; // Ensure this import points to the correct file
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode"; // Corrected to named import

export default function LoginPage() {
    const { t } = useContext(TranslatorContext);
    const navigate = useNavigate();

    const [data, setData] = useState({ username: '', password: '' });
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            try {
                const decodedToken = jwtDecode(authToken); // Corrected to named import
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    // Token is expired, remove it from localStorage
                    localStorage.removeItem('authToken');
                } else {
                    // Token is valid, redirect to ecommerce page
                    navigate("/dashboard");
                }
            } catch (error) {
                console.error("Invalid token", error);
                // Token is invalid, remove it from localStorage
                localStorage.removeItem('authToken');
            }
        }
    }, [navigate]);

    const handleLogin = async () => {
        // Reset validation error
        setValidationError('');
        
        if (!data.username || !data.password) {
            setValidationError('Username and password are required');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await Api.login(data);
            console.log('Response:', response.data);

            if (response.status !== 200) {
                throw new Error('Enter Correct Password and Username');
            }

            // Store the token in local storage
            localStorage.setItem('authToken', response.data.token);
            console.log('Token set to localStorage:', response.data.token);

            // Confirm the token is in local storage
            const storedToken = localStorage.getItem('authToken');
            console.log('Stored token after set:', storedToken);

            navigate("/dashboard");
        } catch (error) {
            console.error('Error during login:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mc-auth">
            <img src="images/pattern.webp" alt="pattern" className="mc-auth-pattern" />
            <div className="mc-auth-group">
                <LogoComponent 
                    src="HeaderLogo.svg"
                    alt="logo"
                    href="/ecommerce"
                    className="mc-auth-logo"
                />
                <h4 className="mc-auth-title">Login to ElfOutdoor</h4>
                <form className="mc-auth-form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                    <IconFieldComponent 
                        icon="person"
                        type="text"
                        classes="w-100 h-sm"
                        placeholder={t('enter_your_username')}
                        value={data.username}
                        onChange={(e) => setData({ ...data, username: e.target.value })}
                    />
                    <IconFieldComponent 
                        icon="lock"
                        type="password"
                        classes="w-100 h-sm"
                        placeholder={t('enter_your_password')}
                        passwordVisible={true}
                        value={data.password}
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                    />
                    {validationError && <div className="error">{validationError}</div>}
                    {error && <div className="error">{error}</div>}
                    <ButtonComponent 
                        className='mc-auth-btn h-sm' 
                        type='submit'
                        disabled={loading}
                    >
                        {loading ? t('loading') : t('login')}
                    </ButtonComponent>
                    <AnchorComponent className="mc-auth-forgot" to="/forgot-password">{t('forgot_password')}</AnchorComponent>
                </form>
                <div className="mc-auth-navigate">
                    <span>{t("don't have an account")}</span>
                    <AnchorComponent to="/register">{t('register')}</AnchorComponent>
                </div>
            </div>
        </div>
    );
}
