/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { TranslatorContext } from "../../context/Translator";
import { Form, Button, Alert, Spinner, Row, Col } from "react-bootstrap";
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";
import { LoadingComponent } from "../../components";
import { ImageUrl } from "../../components/fields";

const IaypProgramPage = () => {
    const { t } = useContext(TranslatorContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [IaypProgramPage, setIaypProgramPage] = useState({
        bannerImage: "",
        bannerTitle: "",
        bannerFlexh1: "",
        bannerFlexh2: "",
        bannerFlexh3: "",
        bannerFlexp1: "",
        bannerFlexp2: "",
        bannerFlexp3: "",
        superHeading: "",
        supersubHeading: "",
        contentHeader: "",
        contentPara1: "",
        contentImg1: "",
        contentPara2: "",
        contentImg2: "",
        purpleText: "",
        imageCentered: "",
        headingImage: "",
        cardText: "",
        category: "",
    });
    const [originalIaypProgramPage, setOriginalIaypProgramPage] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [bannerImagePreview, setBannerImagePreview] = useState(null);
    const [contentImg1Preview, setContentImg1Preview] = useState(null);
    const [contentImg2Preview, setContentImg2Preview] = useState(null);
    const [headingImagePreview, setHeadingImagePreview] = useState(null);
    const [cardTextPreview, setcardTextPreview] = useState(null);
    const [imageCenteredPreview, setImageCenteredPreview] = useState(null);

    // Fetch Ib Program data on component mount
    useEffect(() => {
        const fetchIbProgram = async () => {
            try {
                const response = await Api.getCurriculumProgram('iayp-program'); // Replace with your actual API call
                if (!response.data) {
                    throw new Error("Failed to fetch IB Program data");
                }
                setIaypProgramPage({
                    bannerImage: response.data.bannerImage || "",
                    bannerTitle: response.data.bannerTitle || "",
                    bannerFlexh1: response.data.bannerFlexh1 || "",
                    bannerFlexh2: response.data.bannerFlexh2 || "",
                    bannerFlexh3: response.data.bannerFlexh3 || "",
                    bannerFlexp1: response.data.bannerFlexp1 || "",
                    bannerFlexp2: response.data.bannerFlexp2 || "",
                    bannerFlexp3: response.data.bannerFlexp3 || "",
                    superHeading: response.data.superHeading || "",
                    supersubHeading: response.data.supersubHeading || "",
                    contentHeader: response.data.contentHeader || "",
                    contentPara1: response.data.contentPara1 || "",
                    contentImg1: response.data.contentImg1 || "",
                    contentPara2: response.data.contentPara2 || "",
                    contentImg2: response.data.contentImg2 || "",
                    purpleText: response.data.purpleText || "",
                    imageCentered: response.data.imageCentered || "",
                    headingImage: response.data.headingImage || "",
                    cardText: response.data.cardText || "",
                    category: response.data.category || "",
                });
                setOriginalIaypProgramPage(response.data);
                setBannerImagePreview(ImageUrl(response.data.bannerImage));
                setContentImg1Preview(ImageUrl(response.data.contentImg1));
                setContentImg2Preview(ImageUrl(response.data.contentImg2));
                setHeadingImagePreview(ImageUrl(response.data.headingImage));
                setcardTextPreview(ImageUrl(response.data.cardText));
                setImageCenteredPreview(ImageUrl(response.data.imageCentered));
            } catch (err) {
                setError("Error fetching IB Program data: " + err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchIbProgram();
    }, []);

    // Handle input changes for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setIaypProgramPage({ ...IaypProgramPage, [name]: value });
    };

    // Handle file input changes for images
    const handleFileChange = (name) => (event) => {
        const file = event.target.files[0];
        if (file) {
            setIaypProgramPage({ ...IaypProgramPage, [name]: file });
            const imagePreviewMap = {
                bannerImage: setBannerImagePreview,
                contentImg1: setContentImg1Preview,
                contentImg2: setContentImg2Preview,
                headingImage: setHeadingImagePreview,
                cardText: setcardTextPreview,
                imageCentered: setImageCenteredPreview,
            };
            imagePreviewMap[name](URL.createObjectURL(file));
        }
    };

    // Handle form submission to save updated IB Program data
    const handleSave = async (event) => {
        event.preventDefault();
        setSaving(true);
        setError(null);

        const formData = new FormData();

        // Append only the fields that have changed
        Object.keys(IaypProgramPage).forEach((key) => {
            if (
                IaypProgramPage[key] !== originalIaypProgramPage[key] &&
                IaypProgramPage[key] !== ""
            ) {
                if (key.endsWith("Image")) {
                    if (IaypProgramPage[key] instanceof File) {
                        formData.append(key, IaypProgramPage[key]);
                    }
                } else {
                    formData.append(key, IaypProgramPage[key]);
                }
            }
        });

        try {
            const response = await Api.updateCurriculumProgram(originalIaypProgramPage._id,formData); // Replace with your actual API call
            if (!response.data) {
                throw new Error("Failed to save the IB Program data");
            }
            alert('updated successfully');
            // navigate("/ib-programs"); // Redirect to the IB Programs list or another appropriate page
        } catch (err) {
            setError("Error saving IB Program data: " + err.message);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <PageLayout>
                <LoadingComponent />
            </PageLayout>
        );
    }

    return (
        <PageLayout>
            <Row>
                <Col lg={12}>
                    <div className="mc-card mb-4">
                        <div className="mc-breadcrumb">
                            <h3 className="mc-breadcrumb-title">{t("Edit IB Program")}</h3>
                            <ul className="mc-breadcrumb-list">
                                <li className="mc-breadcrumb-item">
                                    <Link to="/dashboard" className="mc-breadcrumb-link">
                                        {t("Home")}
                                    </Link>
                                </li>
                                {/* <li className="mc-breadcrumb-item">
                                    <Link to="/ib-programs" className="mc-breadcrumb-link">
                                        {t("All IB Programs")}
                                    </Link>
                                </li> */}
                                <li className="mc-breadcrumb-item">{t("Edit IB Program")}</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="mc-form">
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSave}>
                            {/* Banner Title */}
                            <Form.Group controlId="bannerTitle" className="mb-3">
                                <Form.Label>{t("Banner Title")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bannerTitle"
                                    value={IaypProgramPage.bannerTitle}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Image */}
                            <Form.Group controlId="bannerImage" className="mb-3">
                                <Form.Label>{t("Banner Image")}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange("bannerImage")}
                                />
                                {bannerImagePreview && (
                                    <img
                                        src={bannerImagePreview}
                                        alt="Banner Image Preview"
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                            marginTop: "10px",
                                        }}
                                    />
                                )}
                            </Form.Group>

                            {/* Banner Flex H1 */}
                            <Form.Group controlId="bannerFlexh1" className="mb-3">
                                <Form.Label>{t("Banner Flex H1")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bannerFlexh1"
                                    value={IaypProgramPage.bannerFlexh1}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Flex H2 */}
                            <Form.Group controlId="bannerFlexh2" className="mb-3">
                                <Form.Label>{t("Banner Flex H2")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bannerFlexh2"
                                    value={IaypProgramPage.bannerFlexh2}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Flex H3 */}
                            <Form.Group controlId="bannerFlexh3" className="mb-3">
                                <Form.Label>{t("Banner Flex H3")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bannerFlexh3"
                                    value={IaypProgramPage.bannerFlexh3}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Flex P1 */}
                            <Form.Group controlId="bannerFlexp1" className="mb-3">
                                <Form.Label>{t("Banner Flex P1")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="bannerFlexp1"
                                    value={IaypProgramPage.bannerFlexp1}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Flex P2 */}
                            <Form.Group controlId="bannerFlexp2" className="mb-3">
                                <Form.Label>{t("Banner Flex P2")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="bannerFlexp2"
                                    value={IaypProgramPage.bannerFlexp2}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Banner Flex P3 */}
                            <Form.Group controlId="bannerFlexp3" className="mb-3">
                                <Form.Label>{t("Banner Flex P3")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="bannerFlexp3"
                                    value={IaypProgramPage.bannerFlexp3}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Super Heading */}
                            <Form.Group controlId="superHeading" className="mb-3">
                                <Form.Label>{t("Super Heading")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="superHeading"
                                    value={IaypProgramPage.superHeading}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Supersub Heading */}
                            <Form.Group controlId="supersubHeading" className="mb-3">
                                <Form.Label>{t("Supersub Heading")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="supersubHeading"
                                    value={IaypProgramPage.supersubHeading}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Content Header */}
                            <Form.Group controlId="contentHeader" className="mb-3">
                                <Form.Label>{t("Content Header")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="contentHeader"
                                    value={IaypProgramPage.contentHeader}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Content Paragraph 1 */}
                            <Form.Group controlId="contentPara1" className="mb-3">
                                <Form.Label>{t("Content Paragraph 1")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="contentPara1"
                                    value={IaypProgramPage.contentPara1}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            

                            {/* Content Image 1 */}
                            <Form.Group controlId="contentImg1" className="mb-3">
                                <Form.Label>{t("Content Image 1")}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange("contentImg1")}
                                />
                                {contentImg1Preview && (
                                    <img
                                        src={contentImg1Preview}
                                        alt="Content Image 1 Preview"
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                            marginTop: "10px",
                                        }}
                                    />
                                )}
                            </Form.Group>

                            {/* Content Paragraph 2 */}
                            <Form.Group controlId="contentPara2" className="mb-3">
                                <Form.Label>{t("Content Paragraph 2")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="contentPara2"
                                    value={IaypProgramPage.contentPara2}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Content Image 2 */}
                            <Form.Group controlId="contentImg2" className="mb-3">
                                <Form.Label>{t("Content Image 2")}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange("contentImg2")}
                                />
                                {contentImg2Preview && (
                                    <img
                                        src={contentImg2Preview}
                                        alt="Content Image 2 Preview"
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                            marginTop: "10px",
                                        }}
                                    />
                                )}
                            </Form.Group>

                            {/* Purple Text */}
                            <Form.Group controlId="purpleText" className="mb-3">
                                <Form.Label>{t("Purple Text")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="purpleText"
                                    rows={3}
                                    value={IaypProgramPage.purpleText}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Image Centered */}
                            <Form.Group controlId="imageCentered" className="mb-3">
                                <Form.Label>{t("Image Centered")}</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={handleFileChange("imageCentered")}
                                />
                                {imageCenteredPreview && (
                                    <img
                                        src={imageCenteredPreview}
                                        alt="Image Centered Preview"
                                        style={{
                                            width: "200px",
                                            height: "150px",
                                            marginTop: "10px",
                                        }}
                                    />
                                )}
                            </Form.Group>

                          
                           {/* Heading Image (as Text) */}
                            <Form.Group controlId="headingImage" className="mb-3">
                                <Form.Label>{t("Heading Image URL/Text")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="headingImage"
                                    value={IaypProgramPage.headingImage}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* Paragraph Image (as Text) */}
                            <Form.Group controlId="cardText" className="mb-3">
                                <Form.Label>{t("Paragraph Image URL/Text")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cardText"
                                    value={IaypProgramPage.cardText}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>


                            {/* Category */}
                            <Form.Group controlId="category" className="mb-3">
                                <Form.Label>{t("Category")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="category"
                                    value={IaypProgramPage.category}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={saving}>
                                {saving ? <Spinner animation="border" size="sm" /> : t("Save")}
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
};

export default IaypProgramPage;
