/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { LoadingComponent, PaginationComponent } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import FaqTableComponent from "../../components/tables/FaqTableComponent";

export default function FaqsPage() {
  const { t } = useContext(TranslatorContext);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFaqs = async () => {
      setLoading(true);
      try {
        const response = await Api.getFaqs();
        if (!Array.isArray(response.data)) {
          throw new Error("Response data is not an array");
        }
        setFaqs(response.data);
      } catch (error) {
        setError("Error: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const refreshFaqs = () => {
    setLoading(true);
    Api.getFaqs()
      .then((response) => {
        setFaqs(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error: " + error.message);
        setLoading(false);
      });
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const thead = ["Heading", "Category", "No. of Questions", "Actions"];

  if (loading) {
    return (
      <PageLayout>
        <LoadingComponent />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div>Error: {error}</div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card mb-4">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("FAQs")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("Dashboard")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/faqs" className="mc-breadcrumb-link active">
                    {t("FAQs")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <FaqTableComponent
              thead={thead}
              tbody={faqs.slice(
                currentPage * itemsPerPage,
                currentPage * itemsPerPage + itemsPerPage
              )}
              onUpdate={refreshFaqs}
            />
          </div>
        </Col>
      </Row>
      {faqs.length > itemsPerPage && (
        <Row>
          <Col xl={12} className="d-flex justify-content-center">
            <PaginationComponent
              pageCount={pageCount}
              onPageChange={handlePageClick}
              initialPage={currentPage}
            />
          </Col>
        </Row>
      )}
    </PageLayout>
  );
}
