import React from 'react';
import ReactPaginate from 'react-paginate';
// You can uncomment this if you have a custom CSS file for pagination
// import './Pagination.css';

const PaginationComponent = ({ pageCount, handlePageClick, forcePage }) => {
  return (
    <ReactPaginate
      previousLabel={'Previous'}
      nextLabel={'Next'}
      breakLabel={'...'}
      breakClassName={'pagination-break-item'}
      breakLinkClassName={'pagination-break-link'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={'pagination-container'}
      pageClassName={'pagination-page-item'}
      pageLinkClassName={'pagination-page-link'}
      previousClassName={'pagination-previous-item'}
      previousLinkClassName={'pagination-previous-link'}
      nextClassName={'pagination-next-item'}
      nextLinkClassName={'pagination-next-link'}
      activeClassName={'pagination-active'}
      disabledClassName={'pagination-disabled'}
      forcePage={forcePage} // Sync current page
    />
  );
};

export default PaginationComponent;
