/* eslint-disable no-unused-vars */
import React, { useContext , useEffect, useState} from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { EcommerceCardComponent, SalesCardComponent, ProductsCardComponent, RevenueCardComponent, ClientsCardComponent, ActivityCardComponent, OrdersCardComponent } from "../../components/cards";
import PageLayout from "../../layouts/PageLayout";
import heros from "../../assets/data/heros.json";
import Api from "../../api/baseUrl";
import { LoadingComponent } from "../../components";


export default function EcommercePage() {
    const { t, n } = useContext(TranslatorContext);
    const [data1, setData1] = useState(null);
    const [data2, setData2] = useState(null);
    const [data3, setData3] = useState(null);
    const [data4, setData4] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res1 = await Api.getClients();
                const res2 = await Api.getInquiry();
                const res3 = await Api.getAllTour();
                const res4 = await Api.getBlogs();

                setData1(res1.data.totalInstances);
                setData2(res2.data.totalItems);
                setData3(res3.data.totalInstances);
                setData4(res4.data.totalInstances);
            } catch (e) {
                setError("Error message: " + e.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <PageLayout>
                <LoadingComponent />
            </PageLayout>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <PageLayout>
            <div style={{ padding: '15px' }}>
                <Row>
                    <Col xl={12}>
                        <div className="mc-card" style={{ marginBottom: '20px' }}>
                            <div className='mc-breadcrumb'>
                                <h3 className="mc-breadcrumb-title">{t('Dashboard')}</h3>
                                <ul className="mc-breadcrumb-list" style={{ paddingLeft: '0', listStyleType: 'none' }}>
                                    <li className="mc-breadcrumb-item" style={{ display: 'inline', marginRight: '5px' }}>
                                        <Link to='#' className="mc-breadcrumb-link">{t('home')}</Link>
                                    </li>
                                    <li className="mc-breadcrumb-item" style={{ display: 'inline', marginRight: '5px' }}>
                                        <Link to='#' className="mc-breadcrumb-link">{t('dashboard')}</Link>
                                    </li>
                                    <li className="mc-breadcrumb-item" style={{ display: 'inline' }}>{t('Dashboard')}</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} xl={12}>
                        <Row xs={1} sm={2}>
                            <Col style={{ marginBottom: '20px' }}>
                                <EcommerceCardComponent
                                    icon={"account_circle"}
                                    trend={"trending_up"}
                                    title={"total Clients"}
                                    variant={"green"}
                                    number={data1}
                                    percent={40}
                                    redirect={"/clients"}
                                />
                            </Col>
                            <Col style={{ marginBottom: '20px' }}>
                                <EcommerceCardComponent
                                    icon={"shopping_cart"}
                                    trend={"trending_up"}
                                    title={"total Inquiry"}
                                    variant={"purple"}
                                    number={data2}
                                    percent={40}
                                    redirect={"/allInquiry"}
                                />
                            </Col>
                            <Col style={{ marginBottom: '20px' }}>
                                <EcommerceCardComponent
                                    icon={"shopping_bag"}
                                    trend={"trending_up"}
                                    title={"total Tours"}
                                    variant={"blue"}
                                    number={data3}
                                    percent={40}
                                    redirect={"/allTours"}
                                />
                            </Col>
                            <Col style={{ marginBottom: '20px' }}>
                                <EcommerceCardComponent
                                    icon={"hotel_class"}
                                    trend={"trending_up"}
                                    title={"total Blogs"}
                                    variant={"yellow"}
                                    number={data4}
                                    percent={40}
                                    redirect={"/blogs"}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </PageLayout>
    );
}
