import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Alert, Spinner } from 'react-bootstrap';
import Api from '../../api/baseUrl';
import PageLayout from '../../layouts/PageLayout';
import ImageUrl from '../../components/fields/ImageUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
const GalleryPage = () => {
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await Api.getImages();
        if (response.data && response.data[0].pics) {
          setImages(response.data[0].pics);
          setImageId(response.data[0]._id);
        } else {
          setImages([]);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
        setError('Error fetching images');
      }
    };

    fetchImages();
  }, []);

  const handleFileChange = (e, isUploadGallery = false) => {
    const files = Array.from(e.target.files);
    
    if (isUploadGallery) {
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
      setPreviews(prevPreviews => [...prevPreviews, ...files.map(file => URL.createObjectURL(file))]);
    } else {
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
      setPreviews(prevPreviews => [...prevPreviews, ...files.map(file => URL.createObjectURL(file))]);
    }
  };

  const handleUploadClick = (isUploadGallery = false) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = (e) => handleFileChange(e, isUploadGallery);
    input.click();
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) return;

    setUploading(true);
    setError(null);

    try {
      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('pics', file));

      const response = await Api.uploadImages(formData);
      const newImages = response.data.pics;
      setImages(newImages);
      setSelectedFiles([]);
      setPreviews([]);
    } catch (error) {
      console.error('Error uploading images:', error);
      setError('Error uploading images');
    } finally {
      setUploading(false);
    }
  };

  const handleSaveUpload = async () => {
    if (selectedFiles.length === 0) return;
  
    setUploading(true);
    setError(null);
  
    try {
      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('newPics', file));
      formData.append('pics', JSON.stringify(images));
  
      const response = await Api.updateImage(imageId, formData);
  
      // Check if response.data and response.data.pics are defined
      if (response.data && response.data.pics) {
        const newImages = response.data.pics;
        setImages(newImages);
        setSelectedFiles([]);
        setPreviews([]);
      } else {
        setError('Error: No images returned from the server');
      }
    } catch (error) {
      console.error('Error saving images:', error);
      setError('Error saving images');
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (imageUrl) => {
    try {
      const newImages = images.filter((image) => image !== imageUrl);
      const formData = new FormData();
      formData.append('pics', JSON.stringify(newImages));

      const res = await Api.updateImage(imageId, formData);
      console.log(res);
      setImages(newImages);
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Error deleting image');
    }
  };

  const handlePreviewDelete = (index) => {
    const newPreviews = previews.filter((_, i) => i !== index);
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setPreviews(newPreviews);
    setSelectedFiles(newFiles);
  };

  return (
    <PageLayout>
      <Container className="gallery-page">
        <style jsx>{`
          .gallery-item img {
            max-width: 100%;
            height: auto;
          }

          .gallery-item {
            position: relative;
            overflow: hidden;
            background: #f0f0f0; /* Fallback color for when image is broken */
            min-height: 200px; /* Minimum height to prevent buttons overlap */
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
          }

          .gallery-item button {
            z-index: 1;
          }

          .gallery-item img,
          .gallery-item .image-placeholder {
            width: 100%;
            height: auto;
          }

          .gallery-item .image-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #d8d8d8;
            width: 100%;
            height: 100%;
          }

          .position-absolute {
            z-index: 2;
          }

          .upload-button,
          .add-button,
          .save-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 20px;
          }

          .save-button {
            background-color: #28a745;
            margin-top: 20px;
          }

          .h-fixed {
            height: 200px; /* Fixed height for images */
          }
        `}</style>
        <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">Gallery</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">Gallery</li>
            </ul>
          </div>
        </div>
      </Col>
        <Row className="mb-4">
          <Col>
            <h1>Gallery</h1>
            {error && <Alert variant="danger">{error}</Alert>}
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <div className="upload-button" onClick={() => handleUploadClick(true)}>
              <FontAwesomeIcon icon={faPlus} /> Upload Gallery
            </div>
          </Col>
        </Row>

        <Row>
          {previews.length > 0 && (
            <Col>
              <Button
                variant="success"
                className="save-button"
                onClick={handleUpload}
                disabled={uploading}
              >
                {uploading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Save Gallery'
                )}
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          {images.map((image, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <div className="gallery-item position-relative h-fixed">
                {image ? (
                  <img src={ImageUrl(image)} alt="" className="img-fluid" />
                ) : (
                  <div className="image-placeholder">Image not available</div>
                )}
                <Button
                  variant="danger"
                  className="position-absolute top-0 end-0 m-2"
                  onClick={() => handleDelete(image)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </Col>
          ))}
        </Row>

        <Row>
          {previews.map((preview, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <div className="gallery-item position-relative h-fixed">
                <img src={preview} alt="Preview" className="img-fluid" />
                <Button
                  variant="danger"
                  className="position-absolute top-0 end-0 m-2"
                  onClick={() => handlePreviewDelete(index)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </Col>
          ))}
        </Row>

        <Row>
          <Col>
            <div className="add-button" onClick={() => handleUploadClick(false)}>
              <FontAwesomeIcon icon={faPlus} /> Add More Images
            </div>
          </Col>
        </Row>

        {previews.length > 0 && (
          <Row>
            <Col>
              <Button
                variant="primary"
                className="save-button"
                onClick={handleSaveUpload}
                disabled={uploading}
              >
                {uploading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Save Added Images'
                )}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </PageLayout>
  );
};

export default GalleryPage;
