/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate,Link } from "react-router-dom";
// import ReactPaginate from "react-paginate";
import { LoadingComponent, PaginationComponent } from "../../components";

import { BlogTableComponent } from "../../components/tables";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AllBlogs() {
    const { t } = useContext(TranslatorContext);
    const [blogData, setBlogData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage] = useState(10); // Adjust items per page as needed

    const navigate = useNavigate();

    const fetchBlogs = async (page, limit) => {
        try {
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await Api.getBlogs(page, limit);

            if (!response.data) {
                throw new Error('Network response was not ok');
            }

            const { data, total } = response.data;
            setBlogData(data);
            setPageCount(Math.ceil(total / limit));
        } catch (error) {
            if (error.message === 'Authentication token not found' || error.message === 'Token expired') {
                setError('Error: ' + error.message);
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchBlogs(currentPage + 1, perPage); // currentPage + 1 because pages are 0-indexed in ReactPaginate
    }, [currentPage, perPage]);

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const addBlog = () => {
        navigate('/addBlog');
    }

    const thead = ["Name", "Title", "Category","Actions"];

    if (loading) {
        return <PageLayout><LoadingComponent/></PageLayout>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <PageLayout>
            <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">All Blogs</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">All Blogs</li>
            </ul>
          </div>
        </div>
      </Col>
            <Row>
                <Col xl={12}>
                    <div className="mc-card">
                        <div className="mc-card-header">
                            <h4 className="mc-card-title">{t('Blogs')}</h4>
                            <Button variant="primary" onClick={addBlog}>{`Add Blogs`}</Button>
                            {/* <h4 className="mc-card-title" onClick={addBlog}>{t('Add Blogs')}</h4> */}
                        </div>
                        <BlogTableComponent
                            thead={thead}
                            tbody={blogData}
                        />
                        <PaginationComponent pageCount={pageCount} handlePageClick={handlePageClick} />
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
}
