/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, Spinner, Button, Form, Alert } from "react-bootstrap";
// import { AnchorComponent } from "../../components/elements";
import { ImageUrl, LabelFieldComponent, LabelTextareaComponent } from "../../components/fields";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AboutSection() {
  const { t } = useContext(TranslatorContext);

  const [form, setForm] = useState({
    title: "",
    description: "",
    videoUrl: "",
    coverImage: null, // Changed to null to properly handle file input
  });

  const [prevForm, setPrevForm] = useState({}); // Added state for previous form data
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null); // State to hold the ID
  const [existingFile, setExistingFile] = useState(null);
  const [editMode, setEditMode] = useState(false); // State to control edit mode
  const [selectedFile, setSelectedFile] = useState(null); // For preview purposes
  const [error, setError] = useState(""); // State to hold error messages

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Api.getAbout();
      if (response.data) {
        const data = response.data[0];
        setId(data._id);
        setForm({
          title: data.title || "",
          description: data.description || "",
          coverImage: ImageUrl(data.coverImage) || "",
          videoUrl: data.videoUrl || "",
        });
        setPrevForm({
          title: data.title || "",
          description: data.description || "",
          coverImage: ImageUrl(data.coverImage) || "",
          videoUrl: data.videoUrl || "",
        }); // Store the fetched data as previous form data
        setExistingFile(data.coverImage || "");
      } else {
        console.error("Error fetching data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setForm((prevForm) => ({ ...prevForm, coverImage: file }));
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSave = async () => {
    if (!form.title) {
      setError("Title is required.");
      return;
    }

    const formData = new FormData();
    // Add only changed fields to formData
    if (form.title !== prevForm.title) {
      formData.append("title", form.title);
    }
    if (form.description !== prevForm.description) {
      formData.append("description", form.description);
    }
    if (form.coverImage && (typeof form.coverImage !== "string" || form.coverImage !== prevForm.coverImage)) {
      formData.append("file", form.coverImage);
    }
    if (form.videoUrl !== prevForm.videoUrl) {
      formData.append("videoUrl", form.videoUrl);
    }

    setIsLoading(true);
    try {
      const response = await Api.updateAboutById(formData, id);
      if (response.data) {
        const result = response.data;
        setExistingFile(result.fileUrl);
        setForm((prevForm) => ({
          ...prevForm,
          videoUrl: result.videoUrl,
          coverImage: result.fileUrl,
        }));
        setPrevForm({
          title: result.title,
          description: result.description,
          coverImage: result.fileUrl,
          videoUrl: result.videoUrl,
        }); // Update prevForm with the latest data
        setEditMode(false);
        setError(""); // Clear any previous errors
      } else {
        console.error("Error updating about entry");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while saving.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNew = () => {
    setForm({
      title: "",
      description: "",
      videoUrl: "",
      coverImage: null, // Changed to null
    });
    setEditMode(true);
    setPrevForm({}); // Reset previous form data when adding new
  };

  const handlePublish = async () => {
    if (!form.title) {
      setError("Title is required.");
      return;
    }

    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("description", form.description);
    if (form.coverImage && typeof form.coverImage !== "string") {
      formData.append("file", form.coverImage);
    }
    formData.append("videoUrl", form.videoUrl);

    setIsLoading(true);
    try {
      const response = await Api.createAbout(formData);
      if (response.data) {
        const result = response.data;
        console.log("Success:", result);
        // Handle successful publish
      } else {
        console.error("Error creating about entry");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while publishing.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card mb-4">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("About Section")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">{t("About Section")}</li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={7}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t("About Us Data")}</h4>
              <Dropdown bsPrefix="mc-dropdown">
                <Dropdown.Toggle bsPrefix="mc-dropdown-toggle">
                  <i className="material-icons">more_horiz</i>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="mc-dropdown-paper">
                  {!editMode && (
                    <button
                      type="button"
                      className="mc-dropdown-menu"
                      onClick={handleEdit}
                    >
                      <i className="material-icons">edit</i>
                      <span>{t("edit")}</span>
                    </button>
                  )}
                  {editMode && (
                    <button
                      type="button"
                      className="mc-dropdown-menu"
                      onClick={handleSave}
                    >
                      <i className="material-icons">save</i>
                      <span>{t("save")}</span>
                    </button>
                  )}
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">delete</i>
                    <span>{t("delete")}</span>
                  </button>
                  <button type="button" className="mc-dropdown-menu">
                    <i className="material-icons">download</i>
                    <span>{t("download")}</span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Row>
              <Col xl={12}>
                <LabelFieldComponent
                  type="text"
                  label={t("Title")}
                  fieldSize="mb-4 w-100 h-md"
                  name="title"
                  value={form.title}
                  onChange={handleChange}
                  disabled={!editMode} // Disable input field when not in edit mode
                />
              </Col>
              <Col xl={12}>
                <LabelTextareaComponent
                  label={t("Description")}
                  fieldSize="mb-4 w-100 h-text-md"
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  disabled={!editMode}
                  style={{ width: "100%", resize: "vertical", whiteSpace: "pre-wrap"}}
                />
              </Col>
             
              {/* <Col xl={12}>
                
              </Col> */}
              
            </Row>
          </div>
          
        </Col>
        <Col xl={5}>
                <LabelFieldComponent
                  type="text"
                  label={t("Video URL")}
                  fieldSize="mb-4 w-100 h-md"
                  name="videoUrl"
                  value={form.videoUrl}
                  onChange={handleChange}
                  disabled={!editMode} // Disable input field when not in edit mode
                />
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>{t("Cover Image")}</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleFileChange}
                    disabled={!editMode} // Disable file input when not in edit mode
                  />
                  {selectedFile && (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Preview"
                      className="img-preview"
                      style={{ maxWidth: "100%", maxHeight: "150px", objectFit: "contain" }}
                    />
                  )}
                </Form.Group>
                {existingFile && !selectedFile && (
                  <img
                    src={ImageUrl(existingFile)}
                    alt="Existing"
                    className="img-existing"
                    style={{ maxWidth: "100%", maxHeight: "150px", objectFit: "contain", marginRight:"20px" }}
                  />
                )}

                {error && <Alert variant="danger">{error}</Alert>}
                {editMode && (
                  <Button onClick={handleSave} disabled={isLoading} >
                    {isLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      t("Save")
                    )}
                  </Button>
                )}
                {!editMode && (
                  <Button onClick={handleAddNew} >
                    {t("Add New")}
                  </Button>
                )}
              </Col>
      </Row>
    </PageLayout>
  );
}
