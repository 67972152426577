/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { TranslatorContext } from '../../context/Translator';
import { Row, Col, Button } from 'react-bootstrap';
import { useNavigate,Link } from 'react-router-dom';
import ClientsTableComponent from '../../components/tables/ClientsTableComponent';
import PageLayout from '../../layouts/PageLayout';
import Api from '../../api/baseUrl';
import PaginationComponent from '../../components/PaginationComponent';


export default function Partners() {
  const { t } = useContext(TranslatorContext);
  const [partners, setPartners] = useState([]);
  const [status, setStatus] = useState(true); // Default: show active clients
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Initial page is 0
  const [pageCount, setPageCount] = useState(0); // Number of pages
  const itemsPerPage = 10; // Adjust this value as needed
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPartners = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const response = await Api.getClients(currentPage);
        if (!response.data) {
          throw new Error('Network response was not ok');
        }

        const { data, totalPages } = response.data;
        setPartners(data);
        setPageCount(totalPages);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners(); // Fetch data based on currentPage
  }, [currentPage, status]); // Fetch data whenever currentPage or status changes

  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update currentPage based on pagination
  };

  const handleButtonClick = () => {
    navigate('/addClient');
  };

  if (loading) {
    return <PageLayout><div>Loading...</div></PageLayout>;
  }

  if (error) {
    return <PageLayout><div>Error: {error}</div></PageLayout>;
  }

  const thead = ['Partner Name', 'Partner Logo', 'Actions'];

  return (
    <PageLayout>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">All Clients</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">All Clients</li>
            </ul>
          </div>
        </div>
      </Col>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t('Clients Data')}</h4>
              <Button className="btn btn-primary" onClick={handleButtonClick}>
                {t('Add Client')}
              </Button>
            </div>
            {/* <div>
            <img src='https://instagram.fdel72-1.fna.fbcdn.net/v/t51.29350-15/449855922_1846196685792618_481294155022787533_n.jpg?stp=c0.280.720.720a_dst-jpg_e15_s640x640&_nc_ht=instagram.fdel72-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=HucrUq6v_gYQ7kNvgGfzw2_&edm=APU89FABAAAA&ccb=7-5&oh=00_AYAamAYZeYNFLWrGY4-qve3XJpGpvIA6cd4qUXpz-PDzzw&oe=66AD2049&_nc_sid=bc0c2c' alt="Instagram Image" />
        </div> */}
            <ClientsTableComponent
              thead={thead}
              tbody={partners}
              currentPage={currentPage} // Pass currentPage
              status={status}
            />
            <PaginationComponent
              pageCount={pageCount}
              handlePageClick={handlePageClick}
              forcePage={currentPage} // Pass the current page state
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
