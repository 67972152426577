/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button } from "react-bootstrap";
import { LoadingComponent, PaginationComponent } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { useNavigate,Link } from "react-router-dom";
import TeamTableComponent from "../../components/tables/TeamTableComponent"; // Ensure this path is correct

export default function Team() {
  const { t } = useContext(TranslatorContext);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('Authentication token not found');
        }
        
        const response = await Api.getTeam();
        
        if (!response.data) {
          throw new Error('Network response was not ok');
        }

        const data = response.data.data;
        setTeam(data);
        console.log('Team',team)
      } catch (error) {
        if (error.message === 'Authentication token not found' || error.message === 'Token expired') {
          setError('Error: ' + error.message);
          navigate('/login');
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTeam();
  }, []);

  const thead = ["Name", "Designation"];

  const addTeam = () =>{
    navigate(`/addTeamMember`)
  }

  if (loading) {
    return <PageLayout><LoadingComponent/></PageLayout>;
  }

  if (error) {
    return <div>{t('Error:')} {error.message}</div>;
  }

  return (
    <PageLayout>
      <Row>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">All Team Members</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">All Team Members</li>
            </ul>
          </div>
        </div>
      </Col>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t('Team Data')}</h4>
              <Button variant="primary" onClick={addTeam}>{"Add Team member"}</Button>
              {/* <h4 className="mc-card-title" onClick={addTeam}>{t('Add Team member')}</h4> */}
            </div>
            <TeamTableComponent thead={thead} tbody={team} />
            <PaginationComponent />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
