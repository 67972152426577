import React, { useState, useContext } from 'react';
import { TranslatorContext } from "../../context/Translator";
import { Row, Spinner, Col, Container, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import PageLayout from '../../layouts/PageLayout';
import Api from '../../api/baseUrl';

export const AddTestimonials = () => {
  const { t } = useContext(TranslatorContext);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [stars, setStars] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setIsLoading(true);
  
    const testimonial = { title, content, name, position, stars };
    // const token = localStorage.getItem('authToken');
    try {
      const response = await Api.createTestimonial(testimonial)
      console.log(response)
  
      if (response.data) {
        setMessage('Testimonial added successfully!'(response.message.data));
        setTitle(response.data.title);
        setContent(response.data.content);
        setName(response.data.name);
        setPosition(response.data.position);
        setStars(response.data.stars);
      } else {
        setMessage('Failed to add testimonial.');
      }
    } catch (error) {
      if (error.message === 'Authentication token not found' || error.message === 'Token expired') {
       
        setMessage('Error: ' + error.message);
        navigate('/login');
      }
      
    } finally {
      
      setIsLoading(false);
      navigate('/testimonials')
    }
  };

  return (
    <>
    <PageLayout>
      <Container >
        <Row>
      <Col lg={12}>
          <div className="mc-card mb-4">
            <div className='mc-breadcrumb'>
              <h3 className="mc-breadcrumb-title">{t('Add Testimonial')}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('home')}</Link></li>
                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('products')}</Link></li>
                <li className="mc-breadcrumb-item">{t('Add Testimonial')}</li>
              </ul>
            </div>
          </div>
        </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Content:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Position:</Form.Label>
                <Form.Control
                  type="text"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Stars:</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="5"
                  value={stars}
                  onChange={(e) => setStars(e.target.value)}
                  required
                />
              </Form.Group>
              <Button type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
              {isLoading && (
                <div className="text-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </Col>
        </Row>
      </Container>
      </PageLayout>
    </>
  );
};

export default AddTestimonials;
