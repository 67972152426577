import React, { useContext, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { ButtonComponent, AnchorComponent } from "../../components/elements";
import IconFieldComponent from "../../components/fields/IconFieldComponent";
import LogoComponent from "../../components/LogoComponent";
import Api from "../../api/baseUrl";
import { useNavigate } from "react-router-dom";



export default function RegisterPage() {

    const { t } = useContext(TranslatorContext);
    const [data, setData] = useState({ username: '', password: '' ,role:'user'});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const handleRegister = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await Api.register(data)
            console.log(response)

            console.log('Register successful');
            navigate('/login')
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mc-auth">
            <img src="images/pattern.webp" alt="pattern" className="mc-auth-pattern" />
            <div className="mc-auth-group">
                <LogoComponent 
                    src="HeaderLogo.svg"
                    alt="logo"
                    href="/ecommerce"
                    className="mc-auth-logo"
                />
                <h4 className="mc-auth-title">Login to ElfOutdoor</h4>
                <form className="mc-auth-form">
                    <IconFieldComponent 
                        icon="person"
                        type="text"
                        classes="w-100 h-sm"
                        placeholder={t('enter_your_username')}
                        value={data.username}
                        onChange={(e) => setData({ ...data, username: e.target.value })}
                    />
                    <IconFieldComponent 
                         icon="lock"
                         type="password"
                         classes="w-100 h-sm"
                         placeholder={t('enter_your_password')}
                         value={data.password}
                         onChange={(e) => setData({ ...data, password: e.target.value })}
                         passwordVisible
                    />
                    <IconFieldComponent 
                        icon="person_outline"
                        type="select"
                        classes="w-100 h-sm"
                        option={['admin', 'user']} 
                        activeOption={data.role}
                        value={data.role}
                        onChange={(e) => setData({ ...data, role: e.target.value })}
                    />
                    {error && <div className="error">{error}</div>}
            <ButtonComponent 
                className='mc-auth-btn h-sm' 
                type='button'
                onClick={handleRegister}
                disabled={loading}
            >
                {loading ? t('loading') : t('sign_in')}
            </ButtonComponent>
                    <AnchorComponent className="mc-auth-forgot" to="/forgot-password">{t('forgot_password')}</AnchorComponent>
                   
                </form>
                <div className="mc-auth-navigate">
                    <span>Don't have an account?</span>
                    <AnchorComponent to="/login">{t('login')}</AnchorComponent>
                </div>
            </div>
        </div>
    );
}