import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Modal, Form, Spinner, Alert } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function FaqTableComponent({ thead, tbody, onUpdate }) {
  const { t } = useContext(TranslatorContext);
  const [data, setData] = useState([]);
  const [currentFaq, setCurrentFaq] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState({ question: "", answer: "" });
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [switchState, setSwitchState] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (Array.isArray(tbody)) {
      setData(tbody);
      const initialSwitchState = tbody.reduce((acc, item) => {
        acc[item._id] = item.active;
        return acc;
      }, {});
      setSwitchState(initialSwitchState);
    }
  }, [tbody]);

  const openEditModal = (faq) => {
    setCurrentFaq(faq);
    setEditModal(true);
  };

  const openQuestionModal = (faq, question = null) => {
    setCurrentFaq(faq);
    setNewQuestion(question || { question: "", answer: "" });
    setQuestionModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSave = async () => {
    setIsEditLoading(true);
    try {
      const updatedFaq = {
        headerText: currentFaq.headerText,
        headerSummary: currentFaq.headerSummary,
      };
      await Api.updateFaq(currentFaq._id, updatedFaq);
      onUpdate();
      setEditModal(false);
    } catch (error) {
      setError("Error saving FAQ: " + error.message);
    } finally {
      setIsEditLoading(false);
    }
  };

  const handleQuestionSave = async () => {
    try {
      let updatedItems = [];
      if (newQuestion._id) {
        updatedItems = currentFaq.items.map((item) =>
          item._id === newQuestion._id ? newQuestion : item
        );
      } else {
        updatedItems = [...currentFaq.items, newQuestion];
      }

      await Api.updateFaq(currentFaq._id, { items: updatedItems });
      onUpdate();
      setQuestionModal(false);
    } catch (error) {
      setError("Error saving question: " + error.message);
    }
  };

  return (
    <div className="mc-table-responsive">
      {error && <Alert variant="danger">{error}</Alert>}
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr className="text-center">
            {thead.map((heading, index) => (
              <th className="text-center" key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even text-center">
          {Array.isArray(data) &&
            data.map((faq, index) => (
              <tr key={index}>
                <td className="text-center">{faq.headerText}</td>
                <td className="text-center">{faq.category}</td>
                <td className="text-center">
                  {faq.items.length}
                  <ButtonComponent
                    title="Add Question"
                    className="material-icons add"
                    onClick={() => openQuestionModal(faq)}
                    style={{ margin: "0 10px", color: "#3F00FF" }}
                  >
                    add
                  </ButtonComponent>
                  {faq.items.map((item, idx) => (
                    <ButtonComponent
                      key={idx}
                      title="Edit Question"
                      className="material-icons edit"
                      onClick={() => openQuestionModal(faq, item)}
                      style={{ margin: "0 10px", color: "green" }}
                    >
                      edit
                    </ButtonComponent>
                  ))}
                </td>
                <td className="text-center">
                  <ButtonComponent
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => openEditModal(faq)}
                    style={{ margin: "0 10px", color: "green" }}
                  >
                    edit
                  </ButtonComponent>
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={switchState[faq._id] || false}
                        onChange={() => {}}
                        name="activeStatus"
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  /> */}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Modal show={editModal} onHide={() => setEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Edit FAQ")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSave}>
            <Form.Group className="mb-3" controlId="formHeaderText">
              <Form.Label>{t("Header Text")}</Form.Label>
              <Form.Control
                type="text"
                name="headerText"
                value={currentFaq.headerText}
                onChange={(e) => setCurrentFaq({ ...currentFaq, headerText: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formHeaderSummary">
              <Form.Label>{t("Header Summary")}</Form.Label>
              <Form.Control
                type="text"
                name="headerSummary"
                value={currentFaq.headerSummary}
                onChange={(e) => setCurrentFaq({ ...currentFaq, headerSummary: e.target.value })}
              />
            </Form.Group>
            <ButtonComponent
              type="submit"
              disabled={isEditLoading}
              className="primary"
            >
              {isEditLoading ? <Spinner animation="border" size="sm" /> : t("Save Changes")}
            </ButtonComponent>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={questionModal} onHide={() => setQuestionModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t(newQuestion._id ? "Edit Question" : "Add New Question")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleQuestionSave}>
            <Form.Group className="mb-3" controlId="formQuestion">
              <Form.Label>{t("Question")}</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={newQuestion.question}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAnswer">
              <Form.Label>{t("Answer")}</Form.Label>
              <Form.Control
                as="textarea"
                name="answer"
                value={newQuestion.answer}
                onChange={handleInputChange}
              />
            </Form.Group>
            <ButtonComponent
              type="submit"
              className="primary"
            >
              {t(newQuestion._id ? "Save Changes" : "Add Question")}
            </ButtonComponent>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
