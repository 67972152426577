import React, { useContext, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Spinner, Form, Button } from "react-bootstrap";
import { LabelFieldComponent } from "../../components/fields";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AddSliderPage() {
  const { t } = useContext(TranslatorContext);
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  // const [fileType, setFileType] = useState(""); // Not needed anymore but kept for clarity
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleTitleChange = (e) => setTitle(e.target.value);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setError(""); // Clear any previous errors
  };

  const handlePublish = async () => {
    if (!selectedFile || !title) {
      alert("Please provide a title and select a file.");
      return;
    }
    
    setIsLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", selectedFile);
    formData.append("mediaType", selectedFile.type.startsWith("video") ? "video" : "image");

    try {
      const response = await Api.createSlider(formData);

      if (response && response.data) {
        console.log("Success:", response.data);
      } else {
        console.error("Error: Failed to upload slider");
      }
    } catch (error) {
      console.error("Error:", error);
      alert(`Error: Video is already present`);
    } finally {
      setIsLoading(false);
      navigate("/slider");
    }
  };

  return (
    <PageLayout>
      <Row className="flex gap-2">
        <Col xl={12}>
          <div className="mc-card mb-4">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("add_slider")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="#" className="mc-breadcrumb-link">{t("home")}</Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/slider" className="mc-breadcrumb-link">{t("slider")}</Link>
                </li>
                <li className="mc-breadcrumb-item">{t("add_slider")}</li>
              </ul>
            </div>
          </div>
        </Col>
        <Row>
          <Col xl={7}>
            <div className="mc-card">
              <div className="mc-card-header">
                <h4 className="mc-card-title">{t("basic_information")}</h4>
              </div>
              <Row>
                <Col xl={12}>
                  <LabelFieldComponent
                    type="text"
                    label={t("title")}
                    fieldSize="mb-4 w-100 h-md"
                    value={title}
                    onChange={handleTitleChange}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={5}>
            <div className="mt-3">
              <h5>Preview:</h5>
              {selectedFile && (
                selectedFile.type.startsWith("image") ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt={title}
                    style={{ maxHeight: "200px", maxWidth: "100%" }}
                  />
                ) : selectedFile.type.startsWith("video") ? (
                  <video
                    src={URL.createObjectURL(selectedFile)}
                    controls
                    style={{ maxHeight: "200px", maxWidth: "100%" }}
                  />
                ) : (
                  <p>Unsupported media type</p>
                )
              )}
              <Form.Group controlId="formFile" className="mt-3">
                <Form.Label>Upload File</Form.Label>
                <Form.Control 
                  type="file" 
                  onChange={handleFileChange}
                  accept="image/*,video/*" // Allow both images and videos
                />
              </Form.Group>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <Button
                variant="primary"
                type="submit"
                onClick={handlePublish}
                disabled={isLoading || !selectedFile || !title}
                className="mt-3"
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
    </PageLayout>
  );
}
