/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
// import ReactPaginate from "react-paginate";
import { LoadingComponent, PaginationComponent } from "../../components";

import { ReviewTableComponent } from "../../components/tables";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AllReview() {
    const { t } = useContext(TranslatorContext);
    const [reviewData, setReviewData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [perPage] = useState(10); // Adjust items per page as needed
    const [selectedCategory, setSelectedCategory] = useState('Domestic');

    const navigate = useNavigate();

    const fetchReviews = async (page, limit, category) => {
        try {
            
            const response = await Api.getReviews(category);

            if (!response.data) {
                throw new Error('Network response was not ok');
            }

            const data  = response.data;
            // const 
            setReviewData(data);
            console.log(reviewData,data,response)
            // setPageCount(Math.ceil(total / limit));
        } catch (error) {
            if (error.message === 'Authentication token not found' || error.message === 'Token expired') {
                setError('Error: ' + error.message);
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await Api.getCategories();
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchReviews(currentPage + 1, perPage, selectedCategory);
        fetchCategories();
    }, [currentPage, perPage, selectedCategory]);

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const thead = ["Client", "Rating", "Review", "Category"];

    if (loading) {
        return <PageLayout><LoadingComponent/></PageLayout>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <PageLayout>
            <Col xl={12}>
                <div className="mc-card mb-4">
                    <div className="mc-breadcrumb">
                        <h3 className="mc-breadcrumb-title">All Reviews</h3>
                        <ul className="mc-breadcrumb-list">
                            <li className="mc-breadcrumb-item">
                                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
                            </li>
                            <li className="mc-breadcrumb-item">All Reviews</li>
                        </ul>
                    </div>
                </div>
            </Col>
            <Row>
                <Col xl={12}>
                    <div className="mc-card">
                        <div className="mc-card-header">
                            <h4 className="mc-card-title">{t('Review Data')}</h4>
                            <Form.Group controlId="category-select">
                                <Form.Label>Filter by Category:</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    value={selectedCategory || ''} 
                                    onChange={handleCategoryChange}
                                >
                                    <option value="">All Categories</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </div>
                        <ReviewTableComponent
                            thead={thead}
                            tbody={reviewData}
                        />
                        <PaginationComponent 
                            pageCount={pageCount} 
                            handlePageClick={handlePageClick} 
                        />
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
}
