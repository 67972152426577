import React from "react";
import { Form, Button } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";

const BulletPointList = ({ label, points, setPoints }) => {
  if(points.length < 1){
    setPoints([''])
  }
  const handleAddPoint = () => {
    setPoints([...points, ""]);
  };

  const handleRemovePoint = (index) => {
    const newPoints = points.filter((_, i) => i !== index);
    setPoints(newPoints);
  };

  const handlePointChange = (index, value) => {
    const newPoints = points.map((point, i) => (i === index ? value : point));
    setPoints(newPoints);
  };

  return (
    <Form.Group className="mb-3">
      <Form.Label>{label}</Form.Label>
      <ul className="bullet-point-list">
        {points.map((point, index) => (
          <li key={index} className="bullet-point-item">
            <Form.Control
              type="text"
              value={point}
              onChange={(e) => handlePointChange(index, e.target.value)}
              className="bullet-point-input"
            />
            <FaTrash
              style={{ color: "red", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleRemovePoint(index)}
            />
          </li>
        ))}
      </ul>
      <Button variant="primary" onClick={handleAddPoint} className="mt-3">
        Add Point
      </Button>
    </Form.Group>
  );
};

export default BulletPointList;
