/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { TranslatorContext } from "../../context/Translator";
import { Form, Button, Alert, Spinner, Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
// import TagsInput from "react-tagsinput";
import 'react-tagsinput/react-tagsinput.css'; // import styles for TagsInput
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";
import { LoadingComponent } from "../../components";
import { ImageUrl } from "../../components/fields";

const EditBlogPage = () => {
    const { t } = useContext(TranslatorContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [blogData, setBlogData] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [linkLoading, setLinkLoading] = useState(false);
    const [imageLink, setImageLink] = useState('');
    // const [bannerImagePreview, setBannerImagePreview] = useState(null);
    const [originalBlogData, setOriginalBlogData] = useState({}); // State for banner image preview

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await Api.getBlogById(id);
                if (!response.data) {
                    throw new Error("Failed to fetch blog data");
                }
                setBlogData(response.data);
                setOriginalBlogData(response.data); // Set original data
            } catch (error) {
                setError("Error fetching blog data: " + error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchBlog();
    }, [id]);
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBlogData({ ...blogData, [name]: value });
    };

    const handleFileChange = (name) => (event) => {
        const file = event.target.files[0];
        if (name === "coverImage") {
            setBlogData({ ...blogData, [name]: file });
            setImagePreview(URL.createObjectURL(file));
        } else if (name === "bannerImage") {
            setBlogData({ ...blogData, [name]: file });
            setImagePreview(URL.createObjectURL(file));
        }
    };

    // const handleTagChange = (tags) => {
    //     setBlogData({ ...blogData, tag: tags });
    // };

    const handleSave = async (event) => {
        event.preventDefault();
        setSaving(true);
        setError(null);
    
        const formData = new FormData();
    
        Object.keys(blogData).forEach(key => {
            // If the field is an image, check the file type and append accordingly
            if (blogData[key] !== originalBlogData[key]) {
                if (key === 'coverImage' || key === 'bannerImage' || key === 'authorImage') {
                    if (blogData[key] instanceof File) {
                        formData.append(key, blogData[key]);
                    }
                } else {
                    formData.append(key, blogData[key]);
                }
            }
        });
    
        try {
            const response = await Api.updateBlogById(formData, id);
            if (!response.data) {
                throw new Error("Failed to save the blog");
            }
            navigate("/blogs"); // Redirect to blogs list or another appropriate page
        } catch (error) {
            setError("Error saving blog: " + error.message);
        } finally {
            setSaving(false);
        }
    };
    
    // const handleEditorChange = (newContent) => {
    //     setBlogData({ ...blogData, description: newContent });
    //   };


    const handleImageUpload = async () => {
        if (!blogData.coverImage) {
            setError("Please upload an image first.");
            return;
        }

        setLinkLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append("images", blogData.coverImage);

        try {
            const response = await Api.getImageLink(formData);
            const imageUrl = response.data.url;
            setImageLink(imageUrl);
        } catch (error) {
            console.log(error);
            setError("Error uploading image: " + error.message);
        } finally {
            setLinkLoading(false);
        }
    };

    if (loading) {
        return <PageLayout><LoadingComponent /></PageLayout>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <PageLayout>
            <Row>
                <Col lg={12}>
                    <div className="mc-card mb-4">
                        <div className='mc-breadcrumb'>
                            <h3 className="mc-breadcrumb-title">{t('Edit Blog')}</h3>
                            <ul className="mc-breadcrumb-list">
                            <li className="mc-breadcrumb-item">
                                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
                            </li>
                            <li className="mc-breadcrumb-item">
                                <Link to="/blogs" className="mc-breadcrumb-link">All Blogs</Link>
                            </li>
                            <li className="mc-breadcrumb-item">Edit Blog</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <div className="mc-form">
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSave}>
                        <Form.Group controlId="authorName" className="mb-3">
                            <Form.Label>{t("Author Name")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="authorName"
                                value={blogData.authorName || ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="authorImage" className="mb-3">
                            <Form.Label>{t("Author Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange("authorImage")} />
                            {blogData.authorImage && (
                                <img
                                    src={
                                        typeof blogData.authorImage === "string"
                                            ? ImageUrl(blogData.authorImage)
                                            : URL.createObjectURL(blogData.authorImage)
                                    }
                                    alt="Author"
                                    width="100"
                                />
                            )}
                        </Form.Group>

                        {/* <Form.Group controlId="tag" className="mb-3">
                            <Form.Label>{t("Tags")}</Form.Label>
                            <TagsInput
                                value={blogData.tag || []}
                                onChange={handleTagChange}
                                inputProps={{ placeholder: t("Add a tag") }}
                            />
                        </Form.Group> */}

                        {/* <Form.Group controlId="category" className="mb-3">
                            <Form.Label>{t("Category")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="category"
                                value={blogData.category || ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group> */}

                        <Form.Group controlId="title" className="mb-3">
                            <Form.Label>{t("Title")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={blogData.title || ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="slug" className="mb-3">
                            <Form.Label>{t("Slug")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="slug"
                                value={blogData.slug || ""}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="coverImage" className="mb-3">
                            <Form.Label>{t("Cover Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange("coverImage")} />
                            {blogData.coverImage && (
                                <img
                                    src={
                                        typeof blogData.coverImage === "string"
                                            ? ImageUrl(blogData.coverImage)
                                            : URL.createObjectURL(blogData.coverImage)
                                    }
                                    alt="Cover"
                                    width="100"
                                />
                            )}
                        </Form.Group>

                        <Form.Group controlId="bannerImage" className="mb-3">
                            <Form.Label>{t("Banner Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange("bannerImage")} />
                            {blogData.bannerImage && (
                                <img 

                                src = {typeof blogData.bannerImage === "string"
                                ? ImageUrl(blogData.bannerImage)
                                : URL.createObjectURL(blogData.bannerImage)
                                }
                                alt="banner"
                                    width="100"
                                />
                            )}
                        </Form.Group>

                        {/* <Form.Group controlId="description" className="mb-3">
                            <Form.Label>{t("Description")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={blogData.description || ""}
                                onChange={handleEditorChange}
                            />
                        </Form.Group> */}
                        <Form.Group controlId="description" className="mb-3">
                        <Form.Label>{t("Description")}</Form.Label>
                        <JoditEditor
                            value={blogData.description || ""}
                            onChange={(newContent) => setBlogData({ ...blogData, description: newContent })} // Corrected handler
                        />
                        </Form.Group>
                        

                        <Form.Group controlId="content" className="mb-3">
                            <Form.Label>{t("Content")}</Form.Label>
                            <JoditEditor
                                value={blogData.content || ""}
                                onChange={(newContent) => setBlogData({ ...blogData, content: newContent })}
                            />
                        </Form.Group>

                        <Form.Group controlId="imageUpload" className="mb-3">
                            <Form.Label>{t("Upload Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange("coverImage")} />
                            {imagePreview && (
                                <div>
                                    <img src={imagePreview} alt="Image Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
                                    <Button variant="primary" onClick={handleImageUpload} disabled={linkLoading}>
                                        {linkLoading ? "Loading..." : "Get Link"}
                                    </Button>
                                </div>
                            )}
                            {imageLink && (
                                <ul>
                                    <li>
                                        <a href={imageLink} target="_blank" rel="noopener noreferrer">{imageLink}</a>
                                    </li>
                                </ul>
                            )}
                        </Form.Group>

                        <Form.Group controlId="motionEnabled" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="motionEnabled"
                                label={t("Motion Enabled")}
                                checked={blogData.motionEnabled || false}
                                onChange={(e) => setBlogData({ ...blogData, motionEnabled: e.target.checked })}
                            />
                        </Form.Group>

                        <Form.Group controlId="active" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                name="active"
                                label={t("Active")}
                                checked={blogData.status || false}
                                onChange={(e) => setBlogData({ ...blogData, status: e.target.checked })}
                            />
                        </Form.Group>

                        <Button type="submit" disabled={saving}>
                            {saving ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                t("Save")
                            )}
                        </Button>
                    </Form>
                </div>
            </Row>
        </PageLayout>
    );
};

export default EditBlogPage;
