/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Alert } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function InquiryTableComponent({ thead, tbody }) {
  const { t } = useContext(TranslatorContext);
  const [data, setData] = useState([]);
  const [switchState, setSwitchState] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (Array.isArray(tbody)) {
      setData(tbody);
      const initialSwitchState = tbody.reduce((acc, item) => {
        acc[item._id] = item.active;
        return acc;
      }, {});
      setSwitchState(initialSwitchState);
    }
  }, [tbody]);

  const handleSwitchChange = (id) => async (event) => {
    const newActiveStatus = event.target.checked;
    setSwitchState((prev) => ({ ...prev, [id]: newActiveStatus }));

    try {
      const response = await Api.updateInquiryStatus(id, newActiveStatus);
      if (!response.data) {
        throw new Error("Failed to update inquiry status");
      }
    } catch (error) {
      setError("Error updating status: " + error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this inquiry?")) {
      try {
        await Api.deleteInquiryById(id);
        setData(prevData => prevData.filter(item => item._id !== id));
      } catch (error) {
        setError("Error deleting inquiry: " + error.message);
      }
    }
  };

  return (
    <div className="mc-table-responsive">
      {error && <Alert variant="danger">{error}</Alert>}
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr className="text-center">
            {thead.map((item, index) => (
              <th className="text-center" key={index}>{item}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="mc-table-body even text-center">
          {Array.isArray(data) && data.map((item, index) => (
            <tr key={index}>
              <td title="Name" className="text-center">{item.name}</td>
              <td title="Email" className="text-center">{item.email}</td>
              <td title="Mobile" className="text-center">{item.mobile}</td>
              <td className="d-flex justify-content-center align-items-center">
                <div className="mc-table-action d-flex align-items-center">
                  <ButtonComponent
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => handleDelete(item._id)}
                    style={{ margin: "0 10px", color: "red" }}
                  >
                    delete
                  </ButtonComponent>
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={switchState[item._id] || false}
                        onChange={handleSwitchChange(item._id)}
                        name="activeStatus"
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  /> */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
