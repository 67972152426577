
import axios from "axios";




export const hostedApi = axios.create({
  baseURL: "https://api.elf.amazing7studios.com",
  // baseURL: "http://localhost:4000/",
});

hostedApi.interceptors.request.use((req) => {
  const token = localStorage.getItem('authToken')
  req.headers['Authorization'] = `Bearer ${token}`
  return req;
});


  const Api = {}

  Api.login = async(data) => hostedApi.post('/auth/login', data)


  Api.register = async(data) => hostedApi.post('/auth/register', data)

  Api.whoami = async (authToken) => {
    try {
      const response = await hostedApi.get('/auth/whoAmI', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
 
  Api.getTourBySlug = async () => hostedApi.get('/tour/tourBySlug/great-wall-of-china', {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  Api.getAllTour = async ( page, limit ) => {
    try {
      const response = await hostedApi.get('/tour/dashboard', {
        params: { page, limit },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }



  Api.getTourById = async (id) => hostedApi.get(`/tour/tourById/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  });

  Api.updateTourStatus = async (id) => hostedApi.patch(`/tour/${id}`,{
    headers: {'Content-Type': 'application/json'}
  })
  
  // Api.updatetourStatus

  Api.updateTourById = async (data,id) => hostedApi.put(`/tour/tourById/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });


  // Api.register = async(data) => hostedApi.post('auth/register', data)
  Api.addTour = async(data) => hostedApi.post('/tour/addTour', data)

  Api.getTourByCategory = async(category) => hostedApi.get(`/tourPage/${category}`,category,{
    headers:{
      'Content-type': 'multipart/form-data'
    }
  })

  Api.updateTourPageById = async(data,id) => hostedApi.put(`/tourPage/${id}`,data,{
    headers:{'Content-type': 'multipart/form-data'}
  })

  Api.getTestimonials = async() => hostedApi.get('/testimonial/dashboard',{
    headers:{
      'Content-Type': 'application/json'
    }
  })

  Api.updateTestimonialById = async(data,id) => hostedApi.patch(`/testimonial/${id}`,data,{
    headers:{
      'Content-Type': 'application/json'
    }
  })
 
  Api.deleteTestimonialById = async(id) => hostedApi.delete(`/testimonial/${id}`,{
    headers:{'Content-Type': 'application/json'
  }
})

Api.createTestimonial = async(data) => hostedApi.post(`/testimonial/`,data,{
   headers:{'Content-Type': 'application/json'}

})


Api.getSliderDashboard = async() => hostedApi.get(`/slider/getSlider`,
  {
    headers:{'Content-Type': 'application/json'}
  }
)

Api.updateSliderStatus = async(id) => hostedApi.patch(`/slider/updateStatus/${id}`,{
  header :{
    'Content-Type': 'application/json',
  }
})

Api.createSlider = async(data) => hostedApi.post(`/slider/upload`,data,{
 headers:{'Content-Type': 'multipart/form-data'} 
}
)

Api.updateSliderById = async(data,id) => hostedApi.patch(`/slider/${id}`,data,{
  headers:{'Content-Type': 'multipart/form-data'}
})

Api.deleteSliderById = async(id) => hostedApi.delete(`/slider/delete/${id}`,{
  headers: {'Content-Type': 'application/json'},
})

Api.switchMediaType = async(data) => hostedApi.post('/slider/switchMediaType', data,{
  headers: {'Content-Type': 'application/json'},
})


Api.getClients = async (page) => {
  return hostedApi.get(`/client/data`, {
    headers: { 'Content-Type': 'application/json' },
    params: {
      page: page+1,  // Correctly increment the page number
    },
  });
};



Api.updateClientById = async(data,id) => hostedApi.put(`/client/${id}`,data ,{
  headers: {'Content-Type': 'multipart/form-data'}
})


Api.createClient = async(data) => hostedApi.post('/client/',data,{
  headers:{
    'Content-Type':'multipart/form-data'
  }
})

Api.updateClientStatus = async(id) => hostedApi.patch(`/client/${id}`,{
  headers:{
    'Content-Type':'application/json'
  }
})


Api.deleteClientById = async(id) => hostedApi.delete(`/client/${id}`,{
  headers:{'Content-Type':'application/json'}
})


Api.getAbout = async() => hostedApi.get('/about/data',{
  headers:{
    'Content-Type':'application/json'
  }
})



Api.updateAboutById = async(data,id) => hostedApi.patch(`/about/${id}`,data,{
  headers:{'Content-Type':'multipart/form-data'}
})



Api.createAbout = async(data) => hostedApi.post(`/about/upload`,data,{
  headers:{'Content-Type':'multipart/form-data'}
})



Api.getInquiry = async (page, limit) => {
  return await hostedApi.get(`/inquiry/getAll`, {
    headers: { 'Content-Type': 'application/json' },
    params: { page, limit },
  });
};




Api.getInquiryById = async(id) => hostedApi.get(`/inquiry/getById/${id}`,{
  headers:{
    'Content-Type': 'application/json'
  }
})


Api.getTeam = async() => hostedApi.get(`/team/`,{
  headers:{'Content-Type': 'application/json'}
})

Api.getTeamById = async(id) => hostedApi.get(`/team/getById/${id}`,{
  headers:{'Content-Type': 'application/json'}
})


Api.addTeamMember = async(data) => hostedApi.post(`/team/addTeamMember`,data, {
  headers: {'Content-Type':'multipart/form-data'}
})


Api.updateTeamById = async(data,id) => hostedApi.post(`/team/updateMember/${id}`,data, 
  {
    headers:{'Content-Type':'multipart/form-data'}
 })

 Api.updateTeamStatus = async(data,id) => hostedApi.patch(`/team/updateStatus/${id}`,data,{
  headers:{'Content-Type':'application/json'}
 })


 Api.addInstructor = async(data) => hostedApi.post(`/instructor/addInstructor`,data,{ 
  headers:{'Content-Type':'multipart/form-data'}
 })

 Api.updateInstructor = async(data,id) => hostedApi.patch(`/instructor/updateById/${id}`,data,{
  headers:{'Content-Type':'multipart/form-data'}
 })


//  Api.getClients = async (page) => {
//   return hostedApi.get(`/client/data`, {
//     headers: { 'Content-Type': 'application/json' },
//     params: {
//       page: page+1,  // Correctly increment the page number
//     },
//   });
// };

 Api.getAllInstructor = async(page,limit) => {
  return hostedApi.get(`/instructor/`,{
  headers:{'Content-Type':'application/json'},
  params:{
    page:page+1,
    limit:limit
  },
 })}
 

 Api.toggleInstructor = async(id) => hostedApi.patch(`/instructor/toggleActive/${id}`,{
  headers:{'Content-Type':'application/json'}
 })

 Api.getInstructorById = async(id) => hostedApi.get(`/instructor/getInstructor/${id}`,{
  headers:{'Content-Type':'application/json'}
 })

 Api.getSliderById = async (id) => hostedApi.get(`/slider/getSliderViaId/${id}`,{
  headers: {'Content-Type':'application/json'},
 })


 Api.getBlogs = async (page, limit) => {
  return await hostedApi.get(`/blog/getAll`, {
    headers: { 'Content-Type': 'application/json' },
    params: { page, limit },
  });
};

Api.addBlog = async(data) => hostedApi.post(`/blog/createBlog`, data, {
  headers : {
    'Content-Type':'multipart/form-data'
  }
})


Api.getBlogById = async(id) => hostedApi.get(`/blog/get/${id}`, {
  headers : {
    'Content-Type':'application/json'
  }
})

Api.updateBlogStatus =  async (id) => hostedApi.patch(`/blog/toggleActive/${id}`, {
  headers : {'Content-Type':'application/json'}
})


Api.updateBlogById = async (data,id) => hostedApi.patch(`/blog/updtById/${id}`, data, {
  headers:
  {'Content-Type':'multipart/form-data'}
})

Api.getImageLink = async(data) => hostedApi.post('/blog/getImageId', data, {
  headers:{
    'Content-Type':'multipart/form-data'
  }
})

Api.getImages = async() => hostedApi.get('/gallery/all',{
  headers:{
    'Content-Type':'application/json'
  }
})


Api.uploadImages = async(formData) => hostedApi.post('/gallery/',formData,{
  headers:{
    'Content-Type':'multipart/form-data'
  }
})

Api.updateImage = async (imageId,newPics) => hostedApi.patch(`/gallery/updt/${imageId}`,newPics,{
  headers:{
    'Content-Type':'multipart/form-data'
  }
})

Api.deleteImage = async (imageId) => hostedApi.delete(`/gallery/delete/${imageId}`,{
  headers:{
    'Content-Type':'application/json'
  }
}) 



Api.addReview = async (formdata) => hostedApi.post(`/reviews/add`, formdata, {
  headers:{'Content-Type':'multipart/form-data'}
}) 


Api.getReviews = async (category) => hostedApi.get(`/reviews/${category}`,{
  headers:{'Content-Type':'application/json'}
})


Api.updateReview = async (id,data) => hostedApi.patch(`/reviews/updateById/${id}`,data,{
  headers:{'Content-Type':'application/json'} 
})

Api.getFaqByCategory = async(category) => hostedApi.get(`/faq/getFaqByCat/${category}`,{
  headers:{'Content-Type':'application/json'}
})

Api.getFaqs = async () => hostedApi.get(`/faq/getAllfaqs`,{
  headers: {'Content-Type':'application/json'} 
});

Api.updateFaq = async(id,data) => hostedApi.patch(`/faq/updtById/${id}`,data ,{
  headers:{'Content-Type':'application/json'}
})


Api.getCurriculumPage = async() => hostedApi.get('/curriculum/',{
headers:{'Content-Type':'application/json'}
})

Api.updateCurriculum = async(id,data) => hostedApi.patch(`/curriculum/${id}`,data,{
  headers:{
    'Content-Type':'multipart/form-data'
  }
})

Api.getCurriculumProgram = async(category) => hostedApi.get(`/curriculum-specific/${category}`, {
  headers:{'Content-Type':'multipart/form-data'}
})


Api.updateCurriculumProgram = async(id,data) => hostedApi.put(`/curriculum-specific/${id}`,data,{
  headers:{'Content-Type':'multipart/form-data'}
})


export default Api;






