/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { LoadingComponent } from "../../components";

export default function InquiryViewPage() {
    const { t } = useContext(TranslatorContext);
    const [inquiryDetail, setInquiryDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInquiryDetail = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) {
                    throw new Error('Authentication token not found');
                }

                const response = await Api.getInquiryById(id);
                if (!response.data) {
                    throw new Error('Network response was not ok');
                }

                setInquiryDetail(response.data);
            } catch (error) {
                if (error.message === 'Authentication token not found' || error.message === 'Token expired') {
                    setError('Error: ' + error.message);
                    navigate('/login');
                } else {
                    setError(error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchInquiryDetail();
    }, [id]);

    if (loading) {
        return <PageLayout><LoadingComponent/></PageLayout>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <div className="mc-card">
                        <div className="mc-card-header">
                            <h4 className="mc-card-title">{t('Inquiry Detail')}</h4>
                            <Button onClick={() => navigate('/allInquiry')}>{t('Back to Inquiries')}</Button>
                        </div>
                        <div className="mc-card-body">
                            <Row>
                                <Col md={6}>
                                    <p><strong>{t('Name')}:</strong> {inquiryDetail.name}</p>
                                    <p><strong>{t('Email')}:</strong> {inquiryDetail.email}</p>
                                    <p><strong>{t('Mobile')}:</strong> {inquiryDetail.mobile}</p>
                                    <p><strong>{t('Date')}:</strong> {inquiryDetail.date}</p>
                                    {/* <p><strong>{t('NoOfTickets')}:</strong> {inquiryDetail.ticketsQuantity}</p> */}
                                </Col>
                                <Col md={6}>
                                    <p><strong>{t('Number of Tickets')}:</strong> {inquiryDetail.ticketsQuantity}</p>
                                    <p><strong>{t('Tour Place Name')}:</strong> {inquiryDetail.tourId.placeName}</p>
                                    <p><strong>{t('Tour Type')}:</strong> {inquiryDetail.tourId.tourType}</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
}
