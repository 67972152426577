import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";
import Switch from '@mui/material/Switch';
import { useNavigate } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';

export default function BlogTableComponent({ thead = [], tbody = [] }) {
    const [data, setData] = useState([]);
    const [switchState, setSwitchState] = useState({});
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (tbody && Array.isArray(tbody)) {
            setData(tbody);
            const initialSwitchState = {};
            tbody.forEach(item => {
                initialSwitchState[item._id] = item.status;
            });
            setSwitchState(initialSwitchState);
        }
    }, [tbody]);

    const handleEdit = (id) => {
        navigate(`/blog/${id}`);
    };

    const handleSwitchChange = (id) => async (event) => {
        const newActiveStatus = event.target.checked;
        setSwitchState((prev) => ({ ...prev, [id]: newActiveStatus }));

        try {
            await Api.updateBlogStatus(id, { status: newActiveStatus });
            setData((prevData) =>
                prevData.map((item) =>
                    item._id === id ? { ...item, status: newActiveStatus } : item
                )
            );
        } catch (error) {
            console.error("Error updating switch state:", error);
            setError("Error updating switch state: " + error.message);
        }
    };

    const getSwitchLabel = (id) => {
        return switchState[id] ? "Active" : "Inactive";
    };

    return (
        <div className="mc-table-responsive">
            {error && <Alert variant="danger">{error}</Alert>}
            <table className="mc-table">
                <thead className="mc-table-head primary">
                    <tr className="text-center">
                        {thead.map((item, index) => (
                            <th className="text-center" key={index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="mc-table-body even text-center">
                    {data && data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.authorName}</td>
                                <td>{item.title}</td>
                                <td>{item.category}</td>
                                <td className="d-flex justify-content-center">
                                    <div className="mc-table-action">
                                        <ButtonComponent
                                            title="Edit"
                                            className="material-icons edit"
                                            onClick={() => handleEdit(item._id)}
                                        >
                                            edit
                                        </ButtonComponent>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={switchState[item._id] || false}
                                                    onChange={handleSwitchChange(item._id)}
                                                    name="activeSwitch"
                                                    color="primary"
                                                />
                                            }
                                            label={getSwitchLabel(item._id)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={thead.length}>{error ? "Error loading data" : "No data available"}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
