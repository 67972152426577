/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { TranslatorContext } from "../../context/Translator";
import { useParams, useNavigate,Link } from "react-router-dom";
import { Row, Col, Spinner, Card, Form, Button } from "react-bootstrap";
import { BulletPointList, ImageUrl, LabelTextareaComponent } from "../../components/fields";
import PageLayout from "../../layouts/PageLayout";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { FaTrash } from "react-icons/fa6";

import Api from "../../api/baseUrl";
import { LoadingComponent } from "../../components";

export default function ToursPage() {
  const { t } = useContext(TranslatorContext);
  const { id } = useParams();
  const [tourData, setTourData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerImage, setBannerImage] = useState(null);
  const [placeImage, setPlaceImage] = useState(null);
  const [locationImages, setLocationImages] = useState([]);
  const [newLocationImages, setNewLocationImages] = useState([]);
  const navigate = useNavigate();
  const tourTypes = ["International", "Domestic", "In-School", "Trekking"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.getTourById(id);
        if (response) {
          setTourData(response.data);
          setLocationImages(response.data.locationImages || []);
        } else {
          console.log("Error fetching data");
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleSaveClick = async () => {
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append("placeName", tourData.placeName);
    formData.append("tourType", tourData.tourType);
    formData.append("description", tourData.description);
    formData.append("locationDesc", tourData.locationDesc);
    formData.append("map", tourData.map);
    formData.append("locationHistory", tourData.locationHistory);
    formData.append("itinerary", JSON.stringify(tourData.itinerary));
    formData.append("tags", JSON.stringify(tourData.tags));
    formData.append("featurePoints", JSON.stringify(tourData.featurePoints));
    formData.append("includedPoints", JSON.stringify(tourData.includedPoints));
    formData.append("notIncludedPoints", JSON.stringify(tourData.notIncludedPoints));
  
    console.log("Location Images before JSON.stringify:", locationImages);
    // formData.append("locationImages", JSON.stringify(locationImages));
  
    console.log("New Location Images before append:", newLocationImages);
  
    newLocationImages.forEach((file) => {
      formData.append("newLocationImages", file);
    });
  
    if (bannerImage) {
      formData.append("bannerImage", bannerImage);
    }
    if (placeImage) {
      formData.append("placeImage", placeImage);
    }
  
    try {
      console.log("FormData before sending:", formData);
      const response = await Api.updateTourById(formData, id);
  
      console.log("Response:", response.data);
  
      setTourData(response.data);
      setBannerImage(null);
      setPlaceImage(null);
      setLocationImages(response.data.locationImages || []);
      setNewLocationImages([]);
    } catch (error) {
      console.log("Error adding tour:", error);
      if (error.message === "Authentication token not found" || error.message === "Token expired") {
        navigate("/login");
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  

  const handleChange = (field, value) => {
    setTourData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleImageChange = (field, event) => {
    const files = Array.from(event.target.files);
    
    if (field === "bannerImage") {
      setBannerImage(files[0]);
    } else if (field === "placeImage") {
      setPlaceImage(files[0]);
    } else if (field === "locationImages") {
      setNewLocationImages((prevImages) => [...prevImages, ...files]);
      setLocationImages((prevImages) => [...prevImages, ...files.map(file => URL.createObjectURL(file))]);
      console.log("New Location Images:", [...newLocationImages, ...files]);
    }
  };
  

  const handleDeleteImage = (index) => {
    const updatedLocationImages = [...locationImages];
    updatedLocationImages.splice(index, 1);
    setLocationImages(updatedLocationImages);
  };

  const handleTagsChange = (field, tags) => {
    const filteredTags = tags.filter((tag) => tag.trim() !== "");
    handleChange(field, filteredTags);
  };

  const handleItineraryChange = (index, activities) => {
    setTourData((prevData) => {
      const updatedItinerary = [...prevData.itinerary];
      updatedItinerary[index].activities = activities;
      return { ...prevData, itinerary: updatedItinerary };
    });
  };

  const handleItineraryTitleChange = (index, newTitle) => {
    setTourData((prevData) => {
      const updatedItinerary = [...prevData.itinerary];
      updatedItinerary[index].title = newTitle;
      return { ...prevData, itinerary: updatedItinerary };
    });
  };

  const handleDeleteItineraryDay = (index) => {
    setTourData((prevData) => {
      const updatedItinerary = [...prevData.itinerary];
      updatedItinerary.splice(index, 1);
      return { ...prevData, itinerary: updatedItinerary };
    });
  };

  if (isLoading) {
    return <PageLayout><LoadingComponent/></PageLayout>
  }

  return (
    <PageLayout>
      <Row>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">Edit Tour</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">
              <Link to="/allTours" className="mc-breadcrumb-link">All Tour</Link>
                </li>
                <li  className="mc-breadcrumb-item">Edit Tour</li>
            </ul>
          </div>
        </div>
      </Col>
        <Col xl={12}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title className="mb-3">Tour Detail</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Card.Title>{tourData.placeName}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Place Name")}</Form.Label>
                  <Form.Control
                    type="text"
                    value={tourData.placeName}
                    onChange={(e) => handleChange("placeName", e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Tour Type")}</Form.Label>
                  <Form.Select
                    value={tourData.tourType}
                    onChange={(e) => handleChange("tourType", e.target.value)}
                  >
                    <option value="">{t("Select Tour Type")}</option>
                    {tourTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <LabelTextareaComponent
                  label={t("Description")}
                  value={tourData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  isEditable={true}
                  style={{
                    width: "100%",
                    resize: "vertical",
                    whiteSpace: "pre-wrap",
                  }}
                  className="mb-3"
                />

                <LabelTextareaComponent
                  label={t("Location Description")}
                  value={tourData.locationDesc}
                  onChange={(e) =>
                    handleChange("locationDesc", e.target.value)
                  }
                  isEditable={true}
                  style={{
                    width: "100%",
                    resize: "vertical",
                    whiteSpace: "pre-wrap",
                  }}
                  className="mb-3"
                />

                <LabelTextareaComponent
                  label={t("Location History")}
                  value={tourData.locationHistory}
                  onChange={(e) =>
                    handleChange("locationHistory", e.target.value)
                  }
                  isEditable={true}
                  style={{
                    width: "100%",
                    resize: "vertical",
                    whiteSpace: "pre-wrap",
                  }}
                  className="mb-3"
                />

                <div className="mc-product-upload-media mt-5 w-50">
                  {bannerImage && (
                    <div className="mc-product-upload-image">
                      <img
                        src={URL.createObjectURL(bannerImage)}
                        alt="banner"
                        style={{ maxHeight: "200px", maxWidth: "200px" }}
                      />
                    </div>
                  )}
                  {!bannerImage && tourData.bannerImage && (
                    <div className="mc-product-upload-image">
                      <img
                        src={ImageUrl(tourData.bannerImage)}
                        alt="banner"
                        style={{ maxHeight: "200px", maxWidth: "200px" }}
                      />
                    </div>
                  )}
                </div>
                <Form.Group controlId="bannerImage" className="mt-2">
                  <Form.Label>{t("Banner Image")}</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => handleImageChange("bannerImage", e)}
                  />
                </Form.Group>

                <div className="mc-product-upload-media mt-5 w-50">
                  {placeImage && (
                    <div className="mc-product-upload-image">
                      <img
                        src={URL.createObjectURL(placeImage)}
                        alt="place"
                        style={{ maxHeight: "200px", maxWidth: "200px" }}
                      />
                    </div>
                  )}
                  {!placeImage && tourData.placeImage && (
                    <div className="mc-product-upload-image">
                      <img
                        src={ImageUrl(tourData.placeImage)}
                        alt="place"
                        style={{ maxHeight: "200px", maxWidth: "200px" }}
                      />
                    </div>
                  )}
                </div>
                <Form.Group controlId="placeImage" className="mt-2">
                  <Form.Label>{t("Place Image")}</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={(e) => handleImageChange("placeImage", e)}
                  />
                </Form.Group>

                <Form.Group controlId="locationImages" className="mt-3">
                  <Form.Label>{t("Location Images")}</Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={(e) => handleImageChange("locationImages", e)}
                  />
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px', marginTop: '30px' }}>
                    {locationImages.map((image, index) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                        <img
                          src={image}
                          alt={`location-${index}`}
                          style={{ maxHeight: '100px', maxWidth: '100px', display: 'block' }}
                        />
                        {/* <Button
                          variant="danger"
                          size="sm"
                          style={{ position: 'absolute', top: '0', right: '0', transform: 'translate(50%, -50%)' }}
                          onClick={() => handleDeleteImage(index)}
                        > */}
                          <FaTrash
              style={{ color: "red", cursor: "pointer", marginLeft: "10px",position: 'absolute', top: '0', right: '0', transform: 'translate(50%, -50%)' }}
              onClick={() => handleDeleteImage(index)}
            />
                        {/* </Button> */}
                      </div>
                    ))}
                  </div>
                </Form.Group>


                <Form.Group className="mt-3">
                  <Form.Label>{t("Tags")}</Form.Label>
                  <TagsInput
                    value={tourData.tags}
                    onChange={(tags) => handleTagsChange("tags", tags)}
                    inputProps={{ placeholder: t("Add a tag") }}
                  />
                </Form.Group>

                <LabelTextareaComponent
                  label={t("Map Link")}
                  value={tourData.map}
                  onChange={(e) => handleChange("map", e.target.value)}
                  isEditable={true}
                  style={{
                    width: "100%",
                    resize: "vertical",
                    whiteSpace: "pre-wrap",
                  }}
                  className="mb-3"
                />

                <Form.Group className="mb-3">
                  <Form.Label>{t("Feature Points")}</Form.Label>
                  <BulletPointList
                    points={tourData.featurePoints}
                    setPoints={(points) => handleChange("featurePoints", points)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Included Points")}</Form.Label>
                  <BulletPointList
                    points={tourData.includedPoints}
                    setPoints={(points) => handleChange("includedPoints", points)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Not Included Points")}</Form.Label>
                  <BulletPointList
                    points={tourData.notIncludedPoints}
                    setPoints={(points) => handleChange("notIncludedPoints", points)}
                  />
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label>{t("Itinerary")}</Form.Label>
                  {tourData.itinerary.map((day, index) => (
                    <Card key={index} className="mb-3">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Form.Control
                          type="text"
                          value={day.title}
                          onChange={(e) =>
                            handleItineraryTitleChange(index, e.target.value)
                          }
                          placeholder={t("Day Title")}
                        />
                        {/* <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteItineraryDay(index)}
                        > */}
                          <FaTrash
              style={{ color: "red", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleDeleteItineraryDay(index)}
            />
                          {/* <FaTrash /> */}
                        {/* </Button> */}
                      </Card.Header>
                      <Card.Body>
                        <BulletPointList
                          points={day.activities}
                          setPoints={(activities) =>
                            handleItineraryChange(index, activities)
                          }
                        />
                      </Card.Body>
                    </Card>
                  ))}
                </Form.Group>

                <Button
                  variant="primary"
                  onClick={() =>
                    setTourData((prevData) => ({
                      ...prevData,
                      itinerary: [
                        ...prevData.itinerary,
                        { title: "", activities: [] },
                      ],
                    }))
                  }
                >
                  {t("Add Day")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Button
        variant="primary"
        className="mt-4"
        onClick={handleSaveClick}
        disabled={isLoading}
      >
        {t("Save")}
      </Button>
    </PageLayout>
  );
}
