/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Form, Spinner, Button, Alert, Col, Row } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { LoadingComponent } from "../../components";
import { ImageUrl } from "../../components/fields";

export default function EditTeam() {
  const { t } = useContext(TranslatorContext);
  const { id } = useParams();
  const [teamData, setTeamData] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await Api.getTeamById(id);

        if (!response.data) {
          throw new Error("Network response was not ok");
        }

        setTeamData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [id]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData();
    formData.append("name", teamData.name);
    formData.append("designation", teamData.designation);
    formData.append("linkedin", teamData.linkedin);
    formData.append("insta", teamData.insta);
    formData.append("twitter", teamData.twitter);
    formData.append("status", teamData.status);

    if (selectedFile) {
      formData.append("pic", selectedFile);
    }

    try {
      const response = await Api.updateTeamById(formData, id);

      if (!response.data) {
        throw new Error("Network response was not ok");
      }

      setTeamData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <PageLayout>
        <LoadingComponent />
      </PageLayout>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <PageLayout>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">Edit Team Member</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">
              <Link to="/team" className="mc-breadcrumb-link">All Team members</Link>
              </li>
              <li className="mc-breadcrumb-item">Edit Team member</li>
            </ul>
          </div>
        </div>
      </Col>
      <Form onSubmit={handleSave} className="mt-3">
        <Row>
          <Col xl={7}>
            <Form.Group controlId="formName">
              <Form.Label>{t("Name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={teamData.name || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDesignation" className="mt-3">
              <Form.Label>{t("Designation")}</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                value={teamData.designation || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formLinkedin" className="mt-3">
              <Form.Label>{t("LinkedIn")}</Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                value={teamData.linkedin || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInsta" className="mt-3">
              <Form.Label>{t("Instagram")}</Form.Label>
              <Form.Control
                type="text"
                name="insta"
                value={teamData.insta || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTwitter" className="mt-3">
              <Form.Label>{t("Twitter")}</Form.Label>
              <Form.Control
                type="text"
                name="twitter"
                value={teamData.twitter || ""}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formActive" className="mt-3 mb-3">
              <Form.Check
                type="switch"
                label={t("Active")}
                name="active"
                checked={teamData.status || false}
                onChange={(e) =>
                  setTeamData((prevData) => ({
                    ...prevData,
                    status: e.target.checked,
                  }))
                }
              />
            </Form.Group>
          </Col>

          <Col xl={5}>
            <Form.Group controlId="formProfilePicture">
              <Form.Label>{t("Profile Picture")}</Form.Label>
              {selectedFile ? (
                <div className="mb-3">
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Profile"
                    style={{ width: "150px", height: "150px", objectFit: "cover" }}
                  />
                </div>
              ) : teamData.pic ? (
                <div className="mb-3">
                  <img
                    src={ImageUrl(teamData.pic)}
                    alt="Profile"
                    style={{ width: "150px", height: "150px", objectFit: "cover" }}
                  />
                </div>
              ) : null}
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>

            <Button type="submit" disabled={saving} className="mt-3">
              {saving ? <Spinner as="span" animation="border" size="sm" /> : t("Save")}
            </Button>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </PageLayout>
  );
}
