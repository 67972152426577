/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { TranslatorContext } from "../../context/Translator";
import { Form, Button, Alert, Spinner, Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";
import { LoadingComponent } from "../../components";
import { ImageUrl } from "../../components/fields";

const CurriculumPage = () => {
    const { t } = useContext(TranslatorContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [curriculumData, setCurriculumData] = useState({
        headerTitle: "",
        headerContent: "",
        bannerTitle: "",
    });
    const [originalCurriculumData, setOriginalCurriculumData] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [headerImagePreview, setheaderImagePreview] = useState(null);
    const [bannerImagePreview, setbannerImagePreview] = useState(null);

    // Fetch curriculum data on component mount
    useEffect(() => {
        const fetchCurriculum = async () => {
            try {
                const response = await Api.getCurriculumPage(); // Replace with your actual API call
                if (!response.data) {
                    throw new Error("Failed to fetch curriculum data");
                }
                setCurriculumData({
                    headerTitle: response.data.headerTitle || "",
                    headerContent: response.data.headerContent || "",
                    bannerTitle: response.data.bannerTitle || "",
                });
                setOriginalCurriculumData(response.data);

                // Set image previews
                setheaderImagePreview(ImageUrl(response.data.headerImage));
                setbannerImagePreview(ImageUrl(response.data.bannerImage));
            } catch (err) {
                setError("Error fetching curriculum data: " + err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCurriculum();
    }, []);

    // Handle input changes for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurriculumData({ ...curriculumData, [name]: value });
    };

    // Handle file input changes for images
    const handleFileChange = (name) => (event) => {
        const file = event.target.files[0];
        if (file) {
            setCurriculumData({ ...curriculumData, [name]: file });
            if (name === "headerImage") {
                setheaderImagePreview(URL.createObjectURL(file));
            } else if (name === "bannerImage") {
                setbannerImagePreview(URL.createObjectURL(file));
            }
        }
    };

    // Handle form submission to save updated curriculum data
    const handleSave = async (event) => {
        event.preventDefault();
        setSaving(true);
        setError(null);

        const formData = new FormData();

        // Append only the fields that have changed
        Object.keys(curriculumData).forEach((key) => {
            if (
                curriculumData[key] !== originalCurriculumData[key] &&
                curriculumData[key] !== ""
            ) {
                if (key === "headerImage" || key === "bannerImage") {
                    if (curriculumData[key] instanceof File) {
                        formData.append(key, curriculumData[key]);
                    }
                } else {
                    formData.append(key, curriculumData[key]);
                }
            }
        });

        try {
            const response = await Api.updateCurriculum(originalCurriculumData._id,formData); // Replace with your actual API call
            if (!response.data) {
                throw new Error("Failed to save the curriculum");
            }
            alert('updated successfully') // Redirect to the curriculums list or another appropriate page
        } catch (err) {
            setError("Error saving curriculum: " + err.message);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <PageLayout>
                <LoadingComponent />
            </PageLayout>
        );
    }

    return (
        <PageLayout>
            <Row>
                <Col lg={12}>
                    <div className="mc-card mb-4">
                        <div className="mc-breadcrumb">
                            <h3 className="mc-breadcrumb-title">{t("Edit Curriculum")}</h3>
                            <ul className="mc-breadcrumb-list">
                                <li className="mc-breadcrumb-item">
                                    <Link to="/dashboard" className="mc-breadcrumb-link">
                                        {t("Home")}
                                    </Link>
                                </li>
                                {/* <li className="mc-breadcrumb-item">
                                    <Link to="/curriculums" className="mc-breadcrumb-link">
                                        {t("All Curriculums")}
                                    </Link>
                                </li> */}
                                <li className="mc-breadcrumb-item">{t("Edit Curriculum")}</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="mc-form">
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSave}>
                            {/* banner Title */}
                            <Form.Group controlId="bannerTitle" className="mb-3">
                                <Form.Label>{t("banner Title")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bannerTitle"
                                    value={curriculumData.bannerTitle}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* banner Image */}
                            <Form.Group controlId="bannerImage" className="mb-3">
                                <Form.Label>{t("banner Image")}</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange("bannerImage")} />
                                {bannerImagePreview && (
                                    <img
                                        src={bannerImagePreview}
                                        alt="banner Image Preview"
                                        style={{ width: "200px", height: "150px", marginTop: "10px" }}
                                    />
                                )}
                            </Form.Group>

                            {/* header Title */}
                            <Form.Group controlId="headerTitle" className="mb-3">
                                <Form.Label>{t("header Title")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="headerTitle"
                                    value={curriculumData.headerTitle}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            {/* header Image */}
                            <Form.Group controlId="headerImage" className="mb-3">
                                <Form.Label>{t("header Image")}</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange("headerImage")} />
                                {headerImagePreview && (
                                    <img
                                        src={headerImagePreview}
                                        alt="header Image Preview"
                                        style={{ width: "200px", height: "150px", marginTop: "10px" }}
                                    />
                                )}
                            </Form.Group>

                            {/* header Content */}
                            <Form.Group controlId="headerContent" className="mb-3">
                                <Form.Label>{t("header Content")}</Form.Label>
                                <JoditEditor
                                    value={curriculumData.headerContent}
                                    onChange={(newContent) =>
                                        setCurriculumData({ ...curriculumData, headerContent: newContent })
                                    }
                                />
                            </Form.Group>

                            {/* Save Button */}
                            <Button type="submit" disabled={saving}>
                                {saving ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />{" "}
                                        {t("Saving...")}
                                    </>
                                ) : (
                                    t("Save")
                                )}
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </PageLayout>
    );
};

export default CurriculumPage;
