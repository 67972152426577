export { default as ProductsTableComponent } from "./ProductsTableComponent";
export { default as ClientsTableComponent } from "./ClientsTableComponent";
export { default as TrafficsTableComponent } from "./TrafficsTableComponent";
export { default as InvoiceTableComponent } from "./InvoiceTableComponent";
export { default as OrdersTableComponent } from "./OrdersTableComponent";
export { default as UsersTableComponent } from "./UsersTableComponent";
export { default as PagesTableComponent } from "./PagesTableComponent";
export { default as DealsTableComponent } from "./DealsTableComponent";
export { default as SliderTableComponent } from "./SliderTableComponent";
// export { default as AboutTableComponent } from "./AboutTableComponent";
export { default as TestimonialTableComponent} from "./TestimonialTableComponent";
export { default as TourTableComponent } from "./TourTableComponent";
export { default as InquiryTableComponent } from "./InquiryTableComponent";
export { default as TeamTableComponent } from "./TeamTableComponent";
export { default as BlogTableComponent } from "./BlogTableComponent";
export { default as InstructorTableComponent } from "./InstructorTableComponent";
export { default as ReviewTableComponent } from "./ReviewTableComponent";