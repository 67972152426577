/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Form, Button, Alert, Spinner, Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import TagsInput from "react-tagsinput";
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";
import "react-tagsinput/react-tagsinput.css"; // Import the CSS for TagsInput
import { LabelTextareaComponent } from "../../components/fields";

const AddBlogPage = () => {
    const { t } = useContext(TranslatorContext);
    const initialBlogData = {
        authorName: "",
        authorImage: null,
        // tag: [],
        category: "",
        title: "",
        coverImage: null,
        bannerImage: null, // Add bannerImage field
        description: "",
        slug: "",
        content: "",
        motionEnabled: false,
        status: true,
    };

    const [blogData, setBlogData] = useState(initialBlogData);
    const [authorImagePreview, setAuthorImagePreview] = useState(null);
    const [coverImagePreview, setCoverImagePreview] = useState(null);
    const [bannerImagePreview, setBannerImagePreview] = useState(null); // State for banner image preview
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [linkLoading, setLinkLoading] = useState(false);
    const [imageLink, setImageLink] = useState('');
    const Categories = ['Adventure', 'Tours', 'Photography', 'Wildlife'];

    const handleFileChange = (field, previewSetter) => (event) => {
        const file = event.target.files[0];
        setBlogData({ ...blogData, [field]: file });
        previewSetter(URL.createObjectURL(file));
    };

    const handleChange = (field) => (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setBlogData({ ...blogData, [field]: value });
    };

    // const handleTagsChange = (tags) => {
    //     setBlogData({ ...blogData, tag: tags });
    // };

    const handleSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);

        const formData = new FormData();
        for (const key in blogData) {
            if (key === 'tag') {
                formData.append(key, JSON.stringify(blogData[key]));
            } else {
                formData.append(key, blogData[key]);
            }
        }

        try {
            const response = await Api.addBlog(formData);
            if (!response.data) {
                throw new Error("Failed to save the blog");
            }
            // Reset form fields
            setBlogData(initialBlogData);
            setAuthorImagePreview(null);
            setCoverImagePreview(null);
            setBannerImagePreview(null); // Reset banner image preview
            setImagePreview(null);
            setImageLink('');
        } catch (error) {
            console.log(error);
            setError("Error saving blog: " + error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = async () => {
        if (!blogData.coverImage) {
            setError("Please upload an image first.");
            return;
        }

        setLinkLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append("images", blogData.coverImage);

        try {
            const response = await Api.getImageLink(formData);
            const imageUrl = response.data.url;
            setImageLink(imageUrl);
        } catch (error) {
            console.log(error);
            setError("Error uploading image: " + error.message);
        } finally {
            setLinkLoading(false);
        }
    };

    return (
        <PageLayout>
            <Row>
                <Col lg={12}>
                    <div className="mc-card mb-4">
                        <div className='mc-breadcrumb'>
                            <h3 className="mc-breadcrumb-title">{t('Add Blog')}</h3>
                        </div>
                    </div>
                </Col>
                <div className="mc-form">
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSave}>
                        <Form.Group controlId="authorName" className="mb-3">
                            <Form.Label>{t("Author Name")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={blogData.authorName}
                                onChange={handleChange('authorName')}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="authorImage" className="mb-3">
                            <Form.Label>{t("Author Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange('authorImage', setAuthorImagePreview)} required />
                            {authorImagePreview && (
                                <div>
                                    <img src={authorImagePreview} alt="Author Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
                                </div>
                            )}
                        </Form.Group>

                        

                        <Form.Group controlId="category" className="mb-3">
                            <Form.Label>{t("Category")}</Form.Label>
                            <Form.Select
                                value={blogData.category}
                                onChange={handleChange('category')}
                            >
                                <option value="">Select Category</option>
                                {Categories.map((category) => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="title" className="mb-3">
                            <Form.Label>{t("Title")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={blogData.title}
                                onChange={handleChange('title')}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="slug" className="mb-3">
                            <Form.Label>{t("Slug")}</Form.Label>
                            <Form.Control
                                type="text"
                                value={blogData.slug}
                                onChange={handleChange('slug')}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="coverImage" className="mb-3">
                            <Form.Label>{t("Cover Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange('coverImage', setCoverImagePreview)} required />
                            {coverImagePreview && (
                                <div>
                                    <img src={coverImagePreview} alt="Cover Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
                                </div>
                            )}
                        </Form.Group>

                        <Form.Group controlId="bannerImage" className="mb-3">
                            <Form.Label>{t("Banner Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange('bannerImage', setBannerImagePreview)} required />
                            {bannerImagePreview && (
                                <div>
                                    <img src={bannerImagePreview} alt="Banner Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
                                </div>
                            )}
                        </Form.Group>

                        {/* <Form.Group controlId="description" className="mb-3">
                            <Form.Label>{t("Description")}</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={blogData.description}
                                onChange={handleChange('description')}
                                required
                            />
                        </Form.Group> */}
                        <Form.Group controlId="description" className="mb-3">
                            <Form.Label>{t("description")}</Form.Label>
                            <JoditEditor
                                value={blogData.description}
                                onChange={handleChange('description')}
                            />
                        </Form.Group>


                        <Form.Group controlId="content" className="mb-3">
                            <Form.Label>{t("Content")}</Form.Label>
                            <JoditEditor
                                value={blogData.content}
                                onChange={(newContent) => setBlogData({ ...blogData, content: newContent })}
                            />
                        </Form.Group>

                        <Form.Group controlId="imageUpload" className="mb-3">
                            <Form.Label>{t("Upload Image")}</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange('coverImage', setImagePreview)} />
                            {imagePreview && (
                                <div>
                                    <img src={imagePreview} alt="Image Preview" style={{ width: '100px', height: '100px', marginTop: '10px' }} />
                                    <Button variant="primary" onClick={handleImageUpload} disabled={linkLoading}>
                                    {linkLoading ? "Loading..." : "Get Link"}
                                    </Button>
                                </div>
                            )}
                            {imageLink && (
                                <ul>
                                    <li>
                                        <a href={imageLink} target="_blank" rel="noopener noreferrer">{imageLink}</a>
                                    </li>
                                </ul>
                            )}
                        </Form.Group>

                        <Form.Group controlId="motionEnabled" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label={t("Motion Enabled")}
                                checked={blogData.motionEnabled}
                                onChange={handleChange('motionEnabled')}
                            />
                        </Form.Group>

                        <Form.Group controlId="active" className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label={t("Active")}
                                checked={blogData.status}
                                onChange={handleChange('active')}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={loading}>
                            {loading ? <Spinner animation="border" size="sm" /> : t("Save")}
                        </Button>
                    </Form>
                </div>
            </Row>
        </PageLayout>
    );
}

export default AddBlogPage;
