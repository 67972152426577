import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ImageUrl } from '../fields';
import { ButtonComponent } from '../elements';
import Api from '../../api/baseUrl';

// Assume these API functions are implemented elsewhere
// import { updateReviewApi, fetchReviewsApi } from '../api';

export default function ReviewTableComponent({ thead, tbody, setTbody }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);

    // Handle edit button click
    const handleEditClick = (review) => {
        setSelectedReview(review);
        setShowModal(true);
    };

    // Handle close modal
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedReview(null);
    };

    // Handle save changes
    const handleSaveChanges = async () => {
        try {
            // Update review via API
            await Api.updateReview(selectedReview._id, selectedReview);
            // Fetch updated reviews
            const updatedReviews = await Api.getReviews();
            // Update component state with new reviews
            setTbody(updatedReviews);
            // Close modal
            handleCloseModal();
        } catch (error) {
            console.error('Error updating review:', error);
        }
    };

    return (
        <>
            <div className="mc-table-responsive">
                <table className="mc-table">
                    <thead className="mc-table-head primary">
                        <tr>
                            {thead.map((header, index) => (
                                <th className="text-center" key={index}>{header}</th>
                            ))}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="mc-table-body even">
                        {tbody.length > 0 ? (
                            tbody.map((item, index) => (
                                <tr key={index}>
                                    <td title="Name">
                                        <div className="d-flex align-items-center">
                                            <div style={{ width: '50px', height: '50px', borderRadius: '30px', overflow: 'hidden' }}>
                                                <img
                                                    src={ImageUrl(item.picture)}
                                                    alt={item.name}
                                                    className="object-cover"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                            <span className="ms-3 text-lg">{item.name}</span>
                                        </div>
                                    </td>
                                    <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {Array.from({ length: 5 }, (_, index) => (
                                            <FontAwesomeIcon
                                                key={index}
                                                icon={faStar}
                                                style={{
                                                    color: index < item.rating ? '#f59e0b' : '#d1d5db',
                                                    marginRight: '4px',
                                                }}
                                            />
                                        ))}
                                    </td>
                                    <td>{item.content}</td>
                                    <td>{item.category}</td>
                                    <td>
                                        <div className="mc-table-action">
                                            <ButtonComponent
                                                title="Edit"
                                                className="material-icons edit"
                                                onClick={() => handleEditClick(item)}
                                            >
                                                edit
                                            </ButtonComponent>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={thead.length + 1}>No reviews available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Edit Modal */}
            {selectedReview && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Body className='px-5'>
                        <Form>
                            <Form.Group controlId="reviewContent">
                                <Form.Label>Review Content</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedReview.content}
                                    onChange={(e) => setSelectedReview({ ...selectedReview, content: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="reviewRating">
                                <Form.Label>Rating</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedReview.rating}
                                    onChange={(e) => setSelectedReview({ ...selectedReview, rating: e.target.value })}
                                >
                                    {[1, 2, 3, 4, 5].map(rating => (
                                        <option key={rating} value={rating}>{rating}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Form>
                        <div className='mt-3 d-flex gap-2'>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}
