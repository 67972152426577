/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HeaderLayout from "./HeaderLayout";
import SidebarLayout from "./SidebarLayout";
import MainLayout from "./MainLayout";
// import FooterLayout from "./FooterLayout";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../api/baseUrl";

export default function PageLayout({ children }) {
  const [userRole, setUserRole] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const authToken = localStorage.getItem("authToken");
      console.log("Current pathname:", pathname); 

      if (authToken) {
        try {
          
          const response = await Api.whoami(authToken);
          console.log("API response:", response); 

          if (response.status !== 200 || !response.data || response.data.role !== 'admin') {
            throw new Error("Token validation failed");
          }

          const { role } = response.data;
          setUserRole(role);
          console.log("Token is valid, user role:", role);
        } catch (error) {
          console.error("Invalid token", error);
          localStorage.removeItem('authToken')
          navigate('/login'); 
        }
      } else {
        localStorage.removeItem('authToken')
        navigate('/login'); 
      }
    };

    validateToken();
  }, [pathname, navigate]);

  return (
    <>
      <HeaderLayout />
      <SidebarLayout />
      <MainLayout>
        <>
          {children}
          {userRole === 'admin'}
          {/* {pathname !== "/message" ?  : ""} */}
        </>
      </MainLayout>
    </>
  );
}
