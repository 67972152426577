import React, { useState, useEffect } from "react";
import { ButtonComponent } from "../elements";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Api from "../../api/baseUrl"; // Make sure to import your API utility

export default function SliderTableComponent({ thead, tbody }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isEditLoading, setIsEditLoading] = useState(false);

  useEffect(() => {
    setData(tbody);
  }, [tbody]);

  const handleEdit = async (id) => {
    try {
      setIsEditLoading(true);
      navigate(`/edit-slider/${id}`);
    } catch (error) {
      console.error("Error navigating to edit:", error);
    } finally {
      setIsEditLoading(false);
    }
  };

  const onToggleActive = async (id, currentStatus) => {
    try {
      const response = await Api.updateSliderStatus(id, { active: !currentStatus });
      if (response.status === 200) {
        // Update the local data with the new status
        setData(data.map(item =>
          item._id === id ? { ...item, active: !currentStatus } : item
        ));
        alert(response.data.message); // Show success message
      } else if (response.status === 400) {
        alert(response.data.message); 
      }
    } catch (error) {
      console.log("Error updating status:", error);
      alert(error.response.data.message); 
    }
  };

  return (
    <div className="mc-table-responsive">
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr>
            {thead.map((item, index) => (
              <th className="text-center" key={index}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even">
          {data?.map((item, index) => (
            <tr key={index} className="text-center">
              <td title={item.title}>{item.title}</td>
              <td title={item.mediaType}>{item.mediaType}</td>
              <td>
                <Switch
                  checked={item.active || false}
                  onChange={() => onToggleActive(item._id, item.active)}
                  name="activeSwitch"
                  color="primary"
                />
              </td>
              <td>
                <div className="mc-table-action flex justify-content-center">
                  <ButtonComponent
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => handleEdit(item._id)}
                  >
                    edit
                  </ButtonComponent>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
