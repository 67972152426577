import React, { useContext, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Form, Spinner, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AddInstructor() {
  const { t } = useContext(TranslatorContext);
  const navigate = useNavigate();
  const [instructorData, setInstructorData] = useState({
    name: "",
    status: true,
  });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInstructorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData();
    formData.append("name", instructorData.name);
    formData.append("status", instructorData.status);

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    try {
      const response = await Api.addInstructor(formData);
      console.log(response, response.status);

      
      if(response.status===201){
        navigate("/instructors");
      }

      
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <PageLayout>
      
      <Form onSubmit={handleSave}>
        <Form.Group controlId="formName">
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={instructorData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formActive" className="mt-3">
          <Form.Check
            type="switch"
            label={t("Active")}
            name="active"
            checked={instructorData.status}
            onChange={(e) =>
              setInstructorData((prevData) => ({
                ...prevData,
                status: e.target.checked,
              }))
            }
          />
        </Form.Group>

        <Form.Group controlId="formProfilePicture">
          <Form.Label>{t("Profile Picture")}</Form.Label>
          <Form.Control type="file" onChange={handleFileChange} />
          {preview && (
            <div className="mt-3">
              <img
                src={preview}
                alt="Profile Preview"
                style={{ maxHeight: "200px", maxWidth: "100%" }}
              />
            </div>
          )}
        </Form.Group>

        <Button type="submit" disabled={saving} className="mt-3">
          {saving ? <Spinner as="span" animation="border" size="sm" /> : t("Save")}
        </Button>
      </Form>

      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </PageLayout>
  );
}
