import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LoadingComponent, PaginationComponent } from "../../components";
import { SliderTableComponent } from "../../components/tables";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { Link } from "react-router-dom";

export default function AllSlider() {
  const { t } = useContext(TranslatorContext);
  const [allSliders, setAllSliders] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [activeMediaType, setActiveMediaType] = useState("image"); // Default to image

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSliders = async () => {
      setLoading(true);
      try {
        const response = await Api.getSliderDashboard();
        if (!Array.isArray(response.data)) {
          throw new Error("Response data is not an array");
        }
        setAllSliders(response.data);

      } catch (error) {
        setError("Error: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSliders();
  }, []);

  useEffect(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    setSliders(allSliders.slice(start, end));
    setPageCount(Math.ceil(allSliders.length / itemsPerPage));
  }, [allSliders, currentPage, itemsPerPage]);

  const handlePageClick = data => {
    setCurrentPage(data.selected);
  };

  const handleButtonClick = () => {
    navigate(`/addslider/`);
  };

  const handleSwitchMediaType = async (mediaType) => {
    try {
      const response = await Api.switchMediaType({ mediaType });
      if (response && Array.isArray(response.data)) {
        setAllSliders(response.data);
        setActiveMediaType(mediaType);
      } else {
        throw new Error("Response data is not an array");
      }
    } catch (error) {
      setError("Error: " + error.message);
    }
  };

  const thead = ["Title", "Media Type", "Status", "Actions"];

  if (loading) {
    return (
      <PageLayout>
        <LoadingComponent />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div>Error: {error}</div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <div className="mc-card mb-4">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{t("Slider")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="/dashboard" className="mc-breadcrumb-link">
                    {t("home")}
                  </Link>
                </li>
                <li className="mc-breadcrumb-item">{t("Slider")}</li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header d-flex justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <h5>{t("Choose Slider Mode")}</h5>
                <DropdownButton
                  id="dropdown-basic-button"
                  className="ms-2"
                  title={activeMediaType === "video" ? t("Video") : t("Image")}
                >
                  <Dropdown.Item onClick={() => handleSwitchMediaType("video")}>
                    {t("Video")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSwitchMediaType("image")}>
                    {t("Image")}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
              <Button
                variant="primary"
                onClick={handleButtonClick}
              >
                {t("Add Slider")}
              </Button>
            </div>
            <SliderTableComponent thead={thead} tbody={sliders} />
            <PaginationComponent pageCount={pageCount} handlePageClick={handlePageClick} />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
