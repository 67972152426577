/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-unused-vars */
import * as React from "react";

// CSS & SCSS
import "./assets/fonts/inter.css";
import "./assets/fonts/material.css";
import "./assets/fonts/icofont/icofont.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/styles.scss";
// import SliderListPage from "./pages/main/SliderListPage";
// import SliderListPage from "./pages/main/SliderListPage";

// JS & COMPONENTS
import "./i18n";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "./context/Themes";
import { SidebarProvider } from "./context/Sidebar";
import { LoaderProvider } from "./context/Preloader";
import { TranslatorProvider } from "./context/Translator";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage, RegisterPage,  } from "./pages/auth";
import {
  
 
  ErrorPage,
} from "./pages/others";
import {

  AlertPage,
  
} from "./pages/ui";

import {
  EcommercePage,
  
  MessagePage,
  NotificationPage,
  AddSliderPage,
  AllSlider,
  AboutSection,
  Testimonials,
  AddTestimonials,
  ClientsPage,
  AddClient,
  ToursPage,
  AllTours,
  AddTour,
  AllInquiry,
  InquiryViewPage,
  Team,
  EditTeam,
  AddTeamMember,
  AllBlogs,
  AddBlogPage,
  BlogPage,
  EditSlider,
  GalleryPage,
  Instructors,
  EditInstructor,
  InternationalPage,
  TrekkingPage,
  DomesticPage,
  In_SchoolPage,
  AddReview,
  AllReview,
  FaqsPage
} from "./pages/main";
import AddInstructor from "./pages/main/AddInstructor";


const router = createBrowserRouter([
  // MAIN PAGES
  { path: "/dashboard", element: <EcommercePage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/login", element: <LoginPage /> },
 

  { path: "/addslider", element: <AddSliderPage /> },
  { path: "/slider", element: <AllSlider /> },
  { path: "/edit-slider/:id", element: <EditSlider /> },
  { path: "/aboutSection", element: <AboutSection/>},  
  { path: "/testimonials", element: <Testimonials/>},
  { path: "/addTestimonial", element: <AddTestimonials/>},
  { path: "/clients", element: <ClientsPage/>},
  { path: "/addClient", element: <AddClient/>},
  { path: `/tours/:id`, element: <ToursPage/>},
  { path: "/allTours", element: <AllTours/>},
  { path: "/addTours", element: <AddTour/>},
  { path: "/allInquiry", element: <AllInquiry/>},
  { path: '/inquiry/:id', element: <InquiryViewPage/>},
  { path:"/team", element: <Team/>},
  { path:`/team/:id`, element: <EditTeam/>  },
  { path:"/addTeamMember", element: <AddTeamMember/>},
  { path: "/blogs", element: <AllBlogs/>},
  { path: '/addBlog', element: <AddBlogPage/>},
  { path: "/blog/:id", element: <BlogPage/>},
  { path: "/gallery", element: <GalleryPage/>},
  { path: '/instructors',element: <Instructors/> },
  { path : '/addInstructor', element: <AddInstructor/>},
  { path: '/instructor/:id', element: <EditInstructor/>},
  { path: '/international', element: <InternationalPage/>},
  { path: '/trekking', element: <TrekkingPage/> },
  { path: '/in-school', element: <In_SchoolPage/> },
  { path: '/domestic', element: <DomesticPage/>},
  { path: '/addreview', element: <AddReview/>},
  { path: '/reviews', element: <AllReview/> },
  { path: 'faqs', element: <FaqsPage/>},
  // UI PAGES
 
  { path: "/alerts", element: <AlertPage /> },
  

  // OTHER PAGES
  { path: "/", element: <LoginPage/> },
 
  { path: "/error", element: <ErrorPage /> },
]);

createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <LoaderProvider>
      <TranslatorProvider>
        <SidebarProvider>
          <RouterProvider router={router} />
        </SidebarProvider>
      </TranslatorProvider>
    </LoaderProvider>
  </ThemeProvider>
);
