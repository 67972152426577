import React, { useState } from 'react';
import { Container, Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../../api/baseUrl';
import PageLayout from '../../layouts/PageLayout';

const categories = ['International', 'Domestic', 'Trekking', 'In-School'];

export const AddReview = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [rating, setRating] = useState(0);
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (!name || !image || rating <= 0 || !content || !category) {
      setMessage('Please fill in all fields.');
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('picture', image);
    formData.append('rating', rating);
    formData.append('content', content);
    formData.append('category', category);

    try {
      const response = await Api.addReview(formData);

      if (response.status === 200) {
        setMessage('Review added successfully!');
        setName('');
        setImage(null);
        setRating(0);
        setContent('');
        setCategory('');
        setTimeout(() => navigate('/reviews'), 2000); // Redirect after 2 seconds
      } else {
        setMessage('Failed to add review. Please try again.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout>
   
        <Row className="justify-content-center">
        <Col xl={12}>
          <div className="mc-card mb-4">
            <div className="mc-breadcrumb">
              <h3 className="mc-breadcrumb-title">{("add review")}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item">
                  <Link to="#" className="mc-breadcrumb-link">{("home")}</Link>
                </li>
                <li className="mc-breadcrumb-item">
                  <Link to="/reviews" className="mc-breadcrumb-link">{("all reviews")}</Link>
                </li>
                <li className="mc-breadcrumb-item">{("add review")}</li>
              </ul>
            </div>
          </div>
        </Col>
          <Col md={8}>
            {/* <h2 className="mb-4 text-center">Add Review</h2> */}
            <Row>
              <Col md={8}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Rating (1-5):</Form.Label>
                    <Form.Control
                      type="number"
                      value={rating}
                      onChange={(e) => setRating(Number(e.target.value))}
                      min="1"
                      max="5"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Content:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Category:</Form.Label>
                    <Form.Control
                      as="select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      required
                    >
                      <option value="">Select a category</option>
                      {categories.map((cat) => (
                        <option key={cat} value={cat}>
                          {cat}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Button type="submit" className="w-100" disabled={isLoading}>
                    {isLoading ? 'Submitting...' : 'Submit'}
                  </Button>
                  {isLoading && (
                    <div className="text-center mt-3">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                </Form>
                {message && <p className="mt-3 text-center">{message}</p>}
              </Col>
              <Col md={4} className="d-flex flex-column align-items-center">
             
                <Form.Group className="mb-3 text-center">
                <Form.Label>Profile:</Form.Label>
                  {image && (
                    <img
                      src={URL.createObjectURL(image)}
                      alt="Review"
                      className="img-thumbnail rounded-circle mb-3"
                      style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                    />
                  )}
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
     
    </PageLayout>
  );
};

export default AddReview;
