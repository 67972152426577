import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import Api from '../../api/baseUrl';
import PageLayout from '../../layouts/PageLayout';
import { BulletPointList } from "../../components/fields";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { Link } from "react-router-dom";
import { FaTrash } from 'react-icons/fa';  // FontAwesome delete icon

const AddTour = () => {
  const [tourData, setTourData] = useState({
    placeName: '',
    description: '',
    locationDesc: '',
    map: '',
    locationHistory: '',
    tourType: '',
    itinerary: [],
    tags: [],
    featurePoints: [],
    includedPoints: [],
    notIncludedPoints: [],
    locationImages: [],
  });

  const [itineraryTitle, setItineraryTitle] = useState('');
  const [itineraryActivity, setItineraryActivity] = useState('');
  const [itineraryActivities, setItineraryActivities] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);
  const [placeImage, setPlaceImage] = useState(null);

  const tourTypes = ["International", "Domestic", "In-School", "Trekking"];

  const handleChange = (field, value) => {
    setTourData(prevState => ({
      ...prevState,
      [field]: value 
    }));
  };

  const handleTagsChange = (tags) => {
    setTourData(prevState => ({
      ...prevState,
      tags: tags.filter(tag => tag.trim() !== '') 
    }));
  };

  const handleAddItinerary = () => {
    if (itineraryTitle.trim() === '' || itineraryActivities.length === 0) {
      alert('Itinerary title and activities cannot be empty.');
      return;
    }

    const newItineraryItem = {
      title: itineraryTitle,
      activities: itineraryActivities,
    };

    setTourData(prevState => ({
      ...prevState,
      itinerary: [...prevState.itinerary, newItineraryItem],
    }));

    setItineraryTitle('');
    setItineraryActivities([]);
  };

  const handleAddActivity = () => {
    if (itineraryActivity.trim() !== '') {
      setItineraryActivities([...itineraryActivities, itineraryActivity]);
      setItineraryActivity('');
    }
  };

  const handleRemoveActivity = (index) => {
    const newActivities = itineraryActivities.filter((_, i) => i !== index);
    setItineraryActivities(newActivities);
  };

  const handleAddTour = async () => {
    // Validation
    if (
      !tourData.placeName ||
      !tourData.description ||
      !tourData.locationDesc ||
      !tourData.map ||
      !tourData.locationHistory ||
      tourData.itinerary.length === 0
    ) {
      alert('Please fill in all the required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('placeName', tourData.placeName);
    formData.append('description', tourData.description);
    formData.append("tourType", tourData.tourType);
    formData.append('locationDesc', tourData.locationDesc);
    formData.append('map', tourData.map);
    formData.append('locationHistory', tourData.locationHistory);
    formData.append('itinerary', JSON.stringify(tourData.itinerary));
    formData.append('tags', JSON.stringify(tourData.tags));
    formData.append('featurePoints', JSON.stringify(tourData.featurePoints));
    formData.append('includedPoints', JSON.stringify(tourData.includedPoints));
    formData.append('notIncludedPoints', JSON.stringify(tourData.notIncludedPoints));

    if (bannerImage) {
      formData.append('bannerImage', bannerImage);
    }
    if (placeImage) {
      formData.append('placeImage', placeImage);
    }
    tourData.locationImages.forEach((image, index) => {
      formData.append('locationImages', image);
    });

    try {
      console.log(formData);
      const response = await Api.addTour(formData);
      console.log(response.data);
      
      setTourData({
        placeName: '',
        description: '',
        locationDesc: '',
        map: '',
        locationHistory: '',
        itinerary: [],
        tags: [],
        featurePoints: [],
        includedPoints: [],
        notIncludedPoints: [],
        locationImages: [],
      });
      setBannerImage(null);
      setPlaceImage(null);
      
    } catch (error) {
      console.log('Error adding tour:', error);
      
    }
  };

  const handleLocationImagesChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + tourData.locationImages.length > 10) {
      alert('You can only upload up to 10 images.');
      return;
    }
    setTourData(prevState => ({
      ...prevState,
      locationImages: [...prevState.locationImages, ...files]
    }));
  };

  const handleDeleteImage = (index, type) => {
    setTourData(prevState => {
      const updatedImages = [...prevState[type]];
      updatedImages.splice(index, 1);
      return { ...prevState, [type]: updatedImages };
    });
  };

  return (
    <PageLayout>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">Add Tour</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">Add Tour</li>
            </ul>
          </div>
        </div>
      </Col>
      <div style={{ padding: '20px' }}>
        <Form>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Place Name</Form.Label>
            <Form.Control
              type="text"
              value={tourData.placeName}
              onChange={e => handleChange('placeName', e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Tour Type</Form.Label>
            <Form.Select
              value={tourData.tourType}
              onChange={e => handleChange('tourType', e.target.value)}
              style={{ marginBottom: '10px' }}
            >
              <option value="">Select Tour Type</option>
              {tourTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tourData.description}
              onChange={e => handleChange('description', e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Tags</Form.Label>
            <TagsInput
              value={tourData.tags}
              onChange={handleTagsChange}
              addOnBlur={true}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Included Points</Form.Label>
            <BulletPointList
              points={tourData.includedPoints}
              setPoints={(points) => handleChange("includedPoints", points)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Not Included Points</Form.Label>
            <BulletPointList
              points={tourData.notIncludedPoints}
              setPoints={(points) => handleChange("notIncludedPoints", points)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Feature Points</Form.Label>
            <BulletPointList
              points={tourData.featurePoints}
              setPoints={(points) => handleChange("featurePoints", points)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Location Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tourData.locationDesc}
              onChange={e => handleChange('locationDesc', e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Map Link</Form.Label>
            <Form.Control
              type="text"
              value={tourData.map}
              onChange={e => handleChange('map', e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Location History</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tourData.locationHistory}
              onChange={e => handleChange('locationHistory', e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Banner Image</Form.Label>
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              {bannerImage && (
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                  <img
                    src={URL.createObjectURL(bannerImage)}
                    alt="banner"
                    style={{ width: '150px', height: '150px' }}
                  />
                  <FaTrash
                    onClick={() => setBannerImage(null)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '15%',
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '20px'
                    }}
                  />
                </div>
              )}
              <input 
                id="bannerImage" 
                type="file" 
                accept="image/*" 
                onChange={e => setBannerImage(e.target.files[0])}
                style={{ display: 'block' }}
              />
            </div>
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Place Image</Form.Label>
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              {placeImage && (
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                  <img
                    src={URL.createObjectURL(placeImage)}
                    alt="place"
                    style={{ width: '150px', height: '150px' }}
                  />
                  <FaTrash
                    onClick={() => setPlaceImage(null)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '15%',
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '20px'
                    }}
                  />
                </div>
              )}
              <input 
                id="placeImage" 
                type="file" 
                accept="image/*" 
                onChange={e => setPlaceImage(e.target.files[0])}
                style={{ display: 'block' }}
              />
            </div>
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Location Images</Form.Label>
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              {tourData.locationImages.map((image, index) => (
                <div key={index} style={{ position: 'relative', marginBottom: '10px' }}>
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`location-${index}`}
                    style={{ width: '150px', height: '150px' }}
                  />
                  <FaTrash
                    onClick={() => handleDeleteImage(index, 'locationImages')}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '15%',
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '20px'
                    }}
                  />
                </div>
              ))}
              <input
                id="locationImages"
                type="file"
                accept="image/*"
                multiple
                onChange={handleLocationImagesChange}
                style={{ display: 'block' }}
              />
            </div>
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Itinerary Title</Form.Label>
            <Form.Control
              type="text"
              value={itineraryTitle}
              onChange={e => setItineraryTitle(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: '20px' }}>
            <Form.Label>Itinerary Activity</Form.Label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Control
                type="text"
                value={itineraryActivity}
                onChange={e => setItineraryActivity(e.target.value)}
                style={{ marginRight: '10px', flex: 1 }}
              />
              <Button onClick={handleAddActivity}>Add Activity</Button>
            </div>
            {itineraryActivities.length > 0 && (
              <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                {itineraryActivities.map((activity, index) => (
                  <li key={index} style={{ marginBottom: '5px' }}>
                    {activity}
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveActivity(index)}
                      style={{ marginLeft: '10px' }}
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </Form.Group>
          <Button onClick={handleAddItinerary} style={{ marginBottom: '20px' }}>
            Add Itinerary
          </Button>
          <Button variant="primary" onClick={handleAddTour} style={{ marginBottom: '20px' , marginLeft:"20px"}}>
            Save Tour
          </Button>
        </Form>
      </div>
    </PageLayout>
  );
};

export default AddTour;
