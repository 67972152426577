import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { ImageUrl } from "../../components/fields";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { LoadingComponent } from "../../components";
import { Link } from "react-router-dom";

export default function TrekkingPage() {
  const category = "Trekking";
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [previewImages, setPreviewImages] = useState({
    bannerImage: null,
    contentImages: [],
    memories: [],
  });
  const [updatedContentImages, setUpdatedContentImages] = useState([]);
  const [updatedMemories, setUpdatedMemories] = useState([]);
  const [newFiles, setNewFiles] = useState({
    contentImages: [],
    memories: [],
    bannerImage: null,
  });

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await Api.getTourByCategory(category);
        setPageData(response.data);
        setPreviewImages({
          bannerImage: response.data.bannerImage ? ImageUrl(response.data.bannerImage) : null,
          contentImages: response.data.contentImages ? response.data.contentImages.map(img => ImageUrl(img)) : [],
          memories: response.data.memories ? response.data.memories.map(img => ImageUrl(img)) : [],
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPageData({
      ...pageData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === "bannerImage") {
      const newBannerImage = files[0] ? URL.createObjectURL(files[0]) : null;
      setPreviewImages({
        ...previewImages,
        bannerImage: newBannerImage,
      });
      setNewFiles({
        ...newFiles,
        bannerImage: files[0] || null,
      });
    } else if (name === "contentImages") {
      const newContentImages = Array.from(files).map(file => URL.createObjectURL(file));
      setNewFiles({
        ...newFiles,
        contentImages: Array.from(files),
      });
      setUpdatedContentImages(prevImages => [...prevImages, ...Array.from(files)]);
      setPreviewImages({
        ...previewImages,
        contentImages: [...previewImages.contentImages, ...newContentImages],
      });
    } else if (name === "memories") {
      const newMemories = Array.from(files).map(file => URL.createObjectURL(file));
      setNewFiles({
        ...newFiles,
        memories: Array.from(files),
      });
      setUpdatedMemories(prevImages => [...prevImages, ...Array.from(files)]);
      setPreviewImages({
        ...previewImages,
        memories: [...previewImages.memories, ...newMemories],
      });
    }
  };

  const handleDeleteImage = (type, index) => {
    const newPreviewImages = { ...previewImages };
    const updatedNewFiles = { ...newFiles };

    if (type === "contentImages") {
      newPreviewImages.contentImages.splice(index, 1);
      updatedNewFiles.contentImages.splice(index, 1);
      setUpdatedContentImages(updatedContentImages.filter((_, i) => i !== index));
      setPageData({
        ...pageData,
        contentImages: pageData.contentImages.filter((_, i) => i !== index),
      });
    } else if (type === "memories") {
      newPreviewImages.memories.splice(index, 1);
      updatedNewFiles.memories.splice(index, 1);
      setUpdatedMemories(updatedMemories.filter((_, i) => i !== index));
      setPageData({
        ...pageData,
        memories: pageData.memories.filter((_, i) => i !== index),
      });
    } else if (type === "bannerImage") {
      setPreviewImages({
        ...newPreviewImages,
        bannerImage: null,
      });
      setNewFiles({
        ...newFiles,
        bannerImage: null,
      });
      setPageData({
        ...pageData,
        bannerImage: null,
      });
    }

    setPreviewImages(newPreviewImages);
    setNewFiles(updatedNewFiles);
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();
  
      // Append updated content images
      if (Array.isArray(updatedContentImages)) {
        updatedContentImages.forEach(file => {
          formData.append("contentImages", file);
        });
      }
  
      // Append existing content images
      if (Array.isArray(pageData.contentImages)) {
        pageData.contentImages.forEach(img => {
          formData.append("contentImages", img);
        });
      }
  
      // Append updated memories
      if (Array.isArray(updatedMemories)) {
        updatedMemories.forEach(file => {
          formData.append("memoryImages", file);
        });
      }
  
      // Append existing memories
      if (Array.isArray(pageData.memories)) {
        pageData.memories.forEach(img => {
          formData.append("memories", img);
        });
      }
  
      // Append banner image if exists
      if (newFiles.bannerImage) {
        formData.append("bannerImage", newFiles.bannerImage);
      }
  
      // Append other fields if necessary
      if (pageData.bannerText) formData.append("bannerText", pageData.bannerText);
      if (pageData.contentSuperHeading) formData.append("contentSuperHeading", pageData.contentSuperHeading);
      if (pageData.contentHeading) formData.append("contentHeading", pageData.contentHeading);
      if (pageData.content) formData.append("content", pageData.content);
  
      // Check if contentImages length is less than 2
      const totalContentImages = [
        ...(pageData.contentImages || []),
        ...(updatedContentImages || [])
      ];
  
      if (totalContentImages.length < 2) {
        alert('Please select an image for the about us content.');
        return; // Exit the function without sending the request
      }
  
      // Send the request to update the page
      const response = await Api.updateTourPageById(formData, pageData._id);
      alert("Page updated successfully");
  
      // Update the state with the new data
      setPageData(response.data);
      setPreviewImages({
        bannerImage: response.data.bannerImage ? ImageUrl(response.data.bannerImage) : null,
        contentImages: response.data.contentImages ? response.data.contentImages.map(img => ImageUrl(img)) : [],
        memories: response.data.memories ? response.data.memories.map(img => ImageUrl(img)) : [],
      });
  
      // Reset new files and updated images state
      setNewFiles({
        contentImages: [],
        memories: [],
        bannerImage: null,
      });
      setUpdatedContentImages([]);
      setUpdatedMemories([]);
  
    } catch (error) {
      alert("Error updating page: " + error.message);
    }
  };
  

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <PageLayout>
     <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">In-School TourPage</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">In-School TourPage</li>
            </ul>
          </div>
        </div>
      </Col>
      <Form>
        <Row>
          <Col xl={7}>
            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={7} className="w-full">
                Banner Text
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="bannerText"
                  value={pageData.bannerText || ""}
                  onChange={handleInputChange}
                  style={{ marginTop: "5px" }}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={7}>
                Pink Heading
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="contentSuperHeading"
                  value={pageData.contentSuperHeading || ""}
                  onChange={handleInputChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={7}>
                 Heading
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="contentHeading"
                  value={pageData.contentHeading || ""}
                  onChange={handleInputChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={7}>
                 Content summary
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="content"
                  value={pageData.content || ""}
                  onChange={handleInputChange}
                />
              </Col>
            </Form.Group>
          </Col>

          <Col xl={5}>
            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={5}>
                Banner Image
              </Form.Label>
              <Col sm={10}>
                {previewImages.bannerImage && (
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <img
                      src={previewImages.bannerImage}
                      alt="Banner"
                      style={{ height: "150px", width: "350px" }}
                      className="img-thumbnail mb-2"
                    />
                    {/* <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                        color: "red",
                      }}
                      onClick={() => handleDeleteImage("bannerImage")}
                    /> */}
                  </div>
                )}
                <Form.Control
                  type="file"
                  name="bannerImage"
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm={2} xl={5}>
                Content Images
              </Form.Label>
              <Col sm={10}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  {previewImages.contentImages.map((image, index) => (
                    <div key={index} style={{ position: "relative", margin: "5px" }}>
                      <img
                        src={image}
                        alt={`Content ${index}`}
                        style={{ height: "150px", width: "200px" }}
                        className="img-thumbnail mb-2"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDeleteImage("contentImages", index)}
                      />
                    </div>
                  ))}
                </div>
                <Form.Control
                  type="file"
                  name="contentImages"
                  multiple
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>

            
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
          <Form.Group as={Row}>
              <Form.Label column sm={2} xl={5}>
                Memories
              </Form.Label>
              <Col sm={10} md={12} xl={12}>
                <div style={{ display: "flex", flexWrap: "wrap" , width:"100%"}}>
                  {previewImages.memories.map((image, index) => (
                    <div key={index} style={{ position: "relative", margin: "8px" }}>
                      <img
                        src={image}
                        alt={`Memory ${index}`}
                        style={{ height: "150px", width: "190px" }}
                        className="img-thumbnail mb-2"
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => handleDeleteImage("memories", index)}
                      />
                    </div>
                  ))}
                </div>
                <Form.Control
                  type="file"
                  name="memories"
                  multiple
                  onChange={handleFileChange}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>

        <Button onClick={handleSave} variant="primary">
          Save Changes
        </Button>
      </Form>
    </PageLayout>
  );
}
