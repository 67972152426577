import React, { useState, useEffect, useContext } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Modal, Form, Spinner, Alert } from "react-bootstrap";
import { ButtonComponent } from "../elements";
import Api from "../../api/baseUrl";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ImageUrl } from "../fields";

export default function ClientsTableComponent({ thead, tbody, currentPage, status }) {
  const { t } = useContext(TranslatorContext);
  const [data, setData] = useState([]);
  const [partnerData, setPartnerData] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [switchState, setSwitchState] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.getClients(currentPage);
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const fetchedData = response.data.data;
        setData(fetchedData);

        const initialSwitchState = fetchedData.reduce((acc, item) => {
          acc[item._id] = item.active;
          return acc;
        }, {});
        setSwitchState(initialSwitchState);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      }
    };

    fetchData();
  }, [currentPage, status]);

  useEffect(() => {
    if (Array.isArray(tbody)) {
      setData(tbody);
      const initialSwitchState = tbody.reduce((acc, item) => {
        acc[item._id] = item.active;
        return acc;
      }, {});
      setSwitchState(initialSwitchState);
    }
  }, [tbody]);

  const openEditModal = (item) => {
    setPartnerData(item);
    setEditModal(true);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    setIsEditLoading(true);

    const formData = new FormData();
    formData.append("brandName", partnerData.brandName);
    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      const response = await Api.updateClientById(formData, partnerData._id);
      if (!response.data) {
        throw new Error("Failed to update client");
      }
      setEditModal(false);
      setSelectedFile(null);
      setData(prevData => prevData.map(item =>
        item._id === partnerData._id ? { ...item, ...partnerData } : item
      ));
    } catch (error) {
      setError("Error updating client: " + error.message);
    } finally {
      setIsEditLoading(false);
    }
  };

  const handleSwitchChange = (id) => async (event) => {
    const newActiveStatus = event.target.checked;
    setSwitchState((prev) => ({ ...prev, [id]: newActiveStatus }));

    try {
      const response = await Api.updateClientStatus(id, newActiveStatus);
      if (!response.data) {
        throw new Error("Failed to update client status");
      }
    } catch (error) {
      setError("Error updating status: " + error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        await Api.deleteClientById(id);
        setData(prevData => prevData.filter(item => item._id !== id));
      } catch (error) {
        setError("Error deleting client: " + error.message);
      }
    }
  };

  return (
    <div className="mc-table-responsive">
      {error && <Alert variant="danger">{error}</Alert>}
      <table className="mc-table">
        <thead className="mc-table-head primary">
          <tr className="text-center">
            {thead.map((item, index) => (
              <th className="text-center" key={index}>{item}</th>
            ))}
          </tr>
        </thead>
        <tbody className="mc-table-body even text-center">
          {Array.isArray(data) && data.map((item, index) => (
            <tr key={index}>
              <td title="Partner Name" className="text-center">{item.brandName}</td>
              <td title="Partner Logo" className="m-auto">
                <img
                  src={ImageUrl(item.optimizeUrl)}
                  alt={item.brandName}
                  style={{ height: "50px", width: "100px" }}
                />
              </td>
              <td className="d-flex justify-content-center align-items-center">
                <div className="mc-table-action d-flex align-items-center">
                  <ButtonComponent
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => openEditModal(item)}
                    style={{ margin: "0 10px" }}
                  >
                    edit
                  </ButtonComponent>
                  <ButtonComponent
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => handleDelete(item._id)}
                    style={{ margin: "0 10px", color: "red" }}
                  >
                    delete
                  </ButtonComponent>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={switchState[item._id] || false}
                        onChange={handleSwitchChange(item._id)}
                        name="activeStatus"
                        color="primary"
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={editModal} onHide={() => setEditModal(false)} centered style={{ padding: '10px' }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginLeft: "10px" }}>{t("Edit Partner")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEdit}>
            <Form.Group className="mb-3" controlId="formBrandName">
              <Form.Label>{t("Partner Name")}</Form.Label>
              <Form.Control
                type="text"
                value={partnerData.brandName || ""}
                onChange={(e) =>
                  setPartnerData({ ...partnerData, brandName: e.target.value })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>{t("Partner Logo")}</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <ButtonComponent
              type="submit"
              variant="primary"
              disabled={isEditLoading}
            >
              {isEditLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                t("Save Changes")
              )}
            </ButtonComponent>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
