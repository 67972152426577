/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Form, Spinner, Button, Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { LoadingComponent } from "../../components";
import { ImageUrl } from "../../components/fields";
import { ClassNames } from "@emotion/react";

export default function EditInstructor() {
  const { t } = useContext(TranslatorContext);
  const { id } = useParams();
  const [teamData, setTeamData] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await Api.getInstructorById(id);

        if (!response.data) {
          throw new Error("Network response was not ok");
        }

        setTeamData(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamData();
  }, [id]);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData();
    formData.append("name", teamData.name);
    formData.append("status", teamData.status);

    if (selectedFile) {
      formData.append("image", selectedFile);
    }

    try {
      const response = await Api.updateInstructor(formData, id);

      if (!response.data) {
        throw new Error("Network response was not ok");
      }

      setTeamData(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <PageLayout>
        <LoadingComponent />
      </PageLayout>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <PageLayout>
      <Form onSubmit={handleSave} style={{ maxWidth: "600px", margin: "auto" }}>
        <Form.Group controlId="formName" style={{ marginBottom: "15px" }}>
          <Form.Label>{t("Name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={teamData.name || ""}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formActive" style={{ marginBottom: "15px"}} className="mt-3">
          <Form.Check
            type="switch"
            label={t("Active")}
            name="active"
            checked={teamData.status || false}
            onChange={(e) =>
              setTeamData((prevData) => ({
                ...prevData,
                status: e.target.checked,
              }))
            }
          />
        </Form.Group>

        <Form.Group controlId="formProfilePicture" style={{ marginBottom: "15px" }}>
          <Form.Label>{t("Profile Picture")}</Form.Label>
          {selectedFile ? (
            <div style={{ marginBottom: "15px" }}>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Profile"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : teamData.pic ? (
            <div style={{ marginBottom: "15px" }}>
              <img
                src={ImageUrl(teamData.pic)}
                alt="Profile"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : null}
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>

        <Button type="submit" disabled={saving} style={{ width: "100%" }} className="mt-3">
          {saving ? <Spinner as="span" animation="border" size="sm" /> : t("Save")}
        </Button>
      </Form>

      {error && <Alert variant="danger" style={{ marginTop: "15px" }}>{error}</Alert>}
    </PageLayout>
  );
}
