import React from 'react';
import { Table } from 'react-bootstrap';
import { ButtonComponent } from '../elements';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const TourTableComponent = ({ tours, onEditClick, onToggleActive }) => {
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className='text-center'>
          <th>Place</th>
          <th>Tour Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody className='text-center'>
        {tours.map((tour, index) => (
          <tr key={index}>
            <td >{tour.placeName}</td>
            <td>{tour.tourType}</td>
            <td style={{ display: 'flex', justifyContent: 'center' }} >
              <div className='mc-table-action '>
                <ButtonComponent
                  title='Edit'
                  className="material-icons edit"
                  onClick={() => onEditClick(tour._id)}
                >
                  edit
                </ButtonComponent>
                <FormControlLabel
                  control={
                    <Switch
                      checked={tour.status || false}  // Ensure the switch reflects the active status correctly
                      onChange={() => onToggleActive(tour._id, !tour.status)}
                      name="activeSwitch"
                      color="primary"
                      
                    />
                  }
                  label={tour.status ? 'Active' : 'Inactive'}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TourTableComponent;
