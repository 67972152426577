import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col,Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { PaginationComponent } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { TestimonialTableComponent } from "../../components/tables";
import Api from "../../api/baseUrl";
 


export default function Testimonials() {
  const { t } = useContext(TranslatorContext);
  const [testimonials, setTestimonials] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("Authentication token not found");
        }

        const response = await Api.getTestimonials()


        console.log(response)
        if (!response.data) {
          throw new Error("Network response was not ok");
        }

        const data = response.data
        setTestimonials(data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const thead = ["Title", "Content", "Stars", "Name", "Position", "Actions"];

  const handleButtonClick = () => {
    navigate("/addtestimonial");
  };

  return (
    <PageLayout>
      <Row>
      <Col xl={12}>
          <div className="mc-card mb-4">
            <div className='mc-breadcrumb'>
              <h3 className="mc-breadcrumb-title">{t('Testimonials')}</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item"><Link to='/dashboard' className="mc-breadcrumb-link">{t('home')}</Link></li>
                {/* <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">{t('products')}</Link></li> */}
                <li className="mc-breadcrumb-item">{t('Testimonials')}</li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t("Testimonials Data")}</h4>
              <Button
                  variant="primary"
                  onClick={handleButtonClick}
                >
                  {t("Add Testimonial")}
                </Button>
              {/* <h4 className="mc-card-title" onClick={handleButtonClick}>
                {t("Add Testimonial")}
              </h4> */}
            </div>
            <TestimonialTableComponent thead={thead} tbody={testimonials} />
            <PaginationComponent />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
