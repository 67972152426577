import React, { useContext, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Form, Spinner, Button, Alert, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";

export default function AddTeamMember() {
  const { t } = useContext(TranslatorContext);
  const navigate = useNavigate();
  const [teamData, setTeamData] = useState({
    name: "",
    designation: "",
    insta: "",
    linkedin: "",
    twitter: "",
    status: true,
  });
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      // Generate and set image preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTeamData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData();
    formData.append("name", teamData.name);
    formData.append("designation", teamData.designation);
    formData.append("insta", teamData.insta);
    formData.append("linkedin", teamData.linkedin);
    formData.append("twitter", teamData.twitter);
    formData.append("status", teamData.status);

    if (selectedFile) {
      formData.append("pic", selectedFile);
    }

    try {
      const response = await Api.addTeamMember(formData);
      if (response.status !== 201 && response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      navigate("/team");
    } catch (error) {
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <PageLayout>
      <Form onSubmit={handleSave}>
        <Row>
          {/* Column for all form fields except profile picture and save button */}
          <Col xl={7}>
            <Form.Group controlId="formName">
              <Form.Label>{t("Name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={teamData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDesignation">
              <Form.Label>{t("Designation")}</Form.Label>
              <Form.Control
                type="text"
                name="designation"
                value={teamData.designation}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInstagram">
              <Form.Label>{t("Instagram")}</Form.Label>
              <Form.Control
                type="text"
                name="insta"
                value={teamData.insta}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formLinkedIn">
              <Form.Label>{t("LinkedIn")}</Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                value={teamData.linkedin}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formTwitter">
              <Form.Label>{t("Twitter")}</Form.Label>
              <Form.Control
                type="text"
                name="twitter"
                value={teamData.twitter}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formActive">
              <Form.Check
                type="switch"
                label={t("Active")}
                name="active"
                checked={teamData.status}
                onChange={(e) =>
                  setTeamData((prevData) => ({
                    ...prevData,
                    status: e.target.checked,
                  }))
                }
              />
            </Form.Group>
          </Col>

          {/* Column for profile picture input and save button */}
          <Col xl={5}>
            <Form.Group controlId="formProfilePicture">
              <Form.Label>{t("Profile Picture")}</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '200px',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              )}
            </Form.Group>

            <Button type="submit" disabled={saving} className="mt-3">
              {saving ? <Spinner as="span" animation="border" size="sm" /> : t("Save")}
            </Button>
          </Col>
        </Row>
      </Form>

      {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
    </PageLayout>
  );
}
