import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Form, Spinner, Alert, Modal, Button, Col } from "react-bootstrap";
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";

export default function EditFaqPage() {
  const { category } = useParams();
  const navigate = useNavigate();
  const [faqData, setFaqData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [questionModal, setQuestionModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({ question: "", answer: "", index: -1 }); // Initialize with index

  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const response = await Api.getFaqByCategory(category); // Corrected method to fetch FAQ data
        setFaqData(response.data);
      } catch (error) {
        setError("Error fetching FAQ data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFaqData();
  }, [category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaqData((prev) => ({ ...prev, [name]: value }));
  };

  const handleQuestionChange = (e) => {
    const { name, value } = e.target;
    setCurrentQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      await Api.updateFaq(faqData._id, faqData); // Update FAQ data
      navigate("/faqs"); // Redirect to FAQs list page after successful update
    } catch (error) {
      setError("Error saving changes: " + error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddQuestion = () => {
    setCurrentQuestion({ question: "", answer: "", index: -1 }); // Reset the current question for new entry
    setQuestionModal(true);
  };

  const handleEditQuestion = (index) => {
    setCurrentQuestion({ ...faqData.items[index], index }); // Include the index to track the question being edited
    setQuestionModal(true);
  };

  const handleSaveQuestion = async () => {
    try {
      const updatedItems = [...faqData.items];

      if (currentQuestion.index >= 0) {
        // Edit existing question
        updatedItems[currentQuestion.index] = {
          question: currentQuestion.question,
          answer: currentQuestion.answer,
        };
      } else {
        // Add new question
        updatedItems.push({ question: currentQuestion.question, answer: currentQuestion.answer });
      }

      await Api.updateFaq(faqData._id, { ...faqData, items: updatedItems });
      setFaqData((prev) => ({ ...prev, items: updatedItems }));
      setQuestionModal(false);
    } catch (error) {
      setError("Error saving question: " + error.message);
    }
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <PageLayout>
         <Col lg={12}>
                    <div className="mc-card mb-4">
                        <div className="mc-breadcrumb">
                            <h3 className="mc-breadcrumb-title">{("Edit Faq")}</h3>
                            <ul className="mc-breadcrumb-list">
                                <li className="mc-breadcrumb-item">
                                    <Link to="/dashboard" className="mc-breadcrumb-link">
                                        {("Home")}
                                    </Link>
                                </li>
                                <li className="mc-breadcrumb-item">
                                    <Link to="/faqs" className="mc-breadcrumb-link">
                                        {("Faq Page")}
                                    </Link>
                                </li>
                                <li className="mc-breadcrumb-item">{("Edit Faq")}</li>
                            </ul>
                        </div>
                    </div>
                </Col>
      <div className="edit-faq-page">
       
        <Form>
          <Form.Group controlId="formHeaderText">
            <Form.Label>Header Text</Form.Label>
            <Form.Control
              type="text"
              name="headerText"
              value={faqData.headerText}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formHeaderSummary">
            <Form.Label>Header Summary</Form.Label>
            <Form.Control
              type="text"
              name="headerSummary"
              value={faqData.headerSummary}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formCategory">
            <Form.Label>Category</Form.Label>
            <Form.Control
              type="text"
              name="category"
              value={faqData.category}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Questions Section */}
          <Form.Group>
            <Form.Label>Questions</Form.Label>
            {faqData.items && faqData.items.length > 0 ? (
              faqData.items.map((item, index) => (
                <Form.Group key={index} className="mb-3">
                  <Form.Label>Question {index + 1}</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.question}
                    onChange={(e) => {
                      const updatedItems = [...faqData.items];
                      updatedItems[index].question = e.target.value;
                      setFaqData((prev) => ({ ...prev, items: updatedItems }));
                    }}
                    onClick={() => handleEditQuestion(index)}
                  />
                  <Form.Label>Answer</Form.Label>
                  <Form.Control
                    type="text"
                    value={item.answer}
                    onChange={(e) => {
                      const updatedItems = [...faqData.items];
                      updatedItems[index].answer = e.target.value;
                      setFaqData((prev) => ({ ...prev, items: updatedItems }));
                    }}
                    onClick={() => handleEditQuestion(index)}
                  />
                </Form.Group>
              ))
            ) : (
              <p>No questions added yet.</p>
            )}
            <Button onClick={handleAddQuestion} variant="primary">
              Add New Question
            </Button>
          </Form.Group>

          <Button
            onClick={handleSaveChanges}
            disabled={isSaving}
            className="mt-3"
            variant="primary"
          >
            {isSaving ? <Spinner animation="border" size="sm" /> : "Save Changes"}
          </Button>
        </Form>

        {/* Modal for Adding/Editing a Question */}
        <Modal show={questionModal} onHide={() => setQuestionModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="mx-2">{currentQuestion.index >= 0 ? "Edit Question" : "Add New Question"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formQuestion">
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  name="question"
                  value={currentQuestion.question}
                  onChange={handleQuestionChange}
                />
              </Form.Group>
              <Form.Group controlId="formAnswer">
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  name="answer"
                  value={currentQuestion.answer}
                  onChange={handleQuestionChange}
                />
              </Form.Group>
              <Button onClick={handleSaveQuestion} variant="primary mt-2">
                Save Question
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </PageLayout>
  );
}
