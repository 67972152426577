import React, { useState } from 'react';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import PageLayout from '../../layouts/PageLayout';
import Api from '../../api/baseUrl';
import { useNavigate } from 'react-router-dom';

export const AddClient = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    if (!name || !image) {
      setMessage('Please provide a name and select an image.');
      setIsLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', image);

    const token = localStorage.getItem('authToken');
    if (!token) {
      setMessage('Authentication token is missing. Please log in.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await Api.createClient(formData)

      if (response.data) {
        setMessage('Partner added successfully!');
        setName('');
        setImage(null);
        // Reset the file input
        
      } else {
        const errorData = await response.json();
        setMessage(`Failed to add partner: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
      navigate('/clients')
    }
  };

  return (
    <PageLayout>
      <Row>
        <Col lg={12}>
          <div className="mc-card mb-4">
            <div className='mc-breadcrumb'>
              <h3 className="mc-breadcrumb-title">Add Partner</h3>
              <ul className="mc-breadcrumb-list">
                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">Home</Link></li>
                <li className="mc-breadcrumb-item"><Link to='#' className="mc-breadcrumb-link">Products</Link></li>
                <li className="mc-breadcrumb-item">Add Partner</li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Image:</Form.Label>
                <Form.Control
                  id="file-input"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  required
                />
              </Form.Group>
              <Button type="submit" className="w-100" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </Button>
              {isLoading && (
                <div className="text-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default AddClient;
