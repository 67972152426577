import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col,Button } from "react-bootstrap";
import { useNavigate,Link } from "react-router-dom";
import TourTableComponent from "../../components/tables/TourTableComponent";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import PaginationComponent from "../../components/PaginationComponent";
import { LoadingComponent } from "../../components";
// import AddTour from "./AddTour";

const limit = 10; // Number of items per page

export default function AllTours() {
  const { t } = useContext(TranslatorContext);
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); 

  
  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await Api.getAllTour({ page: currentPage, limit });

        if (!response || !response.data) {
          throw new Error("Network response was not ok");
        }

        const { data, totalPages } = response.data;

        setTours(data || []);
        setPageCount(totalPages);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTours();
  }, [currentPage]);

  const handleEditClick = (tourId) => {
    navigate(`/tours/${tourId}`);
  };
  
  const handleToggleActive = async (tourId) => {
    try {
      const response = await Api.updateTourStatus(tourId);
  
      if (!response.status === 200) {
        throw new Error('Failed to update tour activity');
      }
  
      // Update local state to reflect the toggled isActive status
      setTours((prevTours) =>
        prevTours.map((tour) =>
          tour._id === tourId ? { ...tour, status: !tour.status } : tour
        )
      );
    } catch (error) {
      console.error('Error toggling tour activity:', error);
    }
  };

  const addTour = () => {
    navigate('/addTours')
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1); 
  };

  if (loading) {
    return <PageLayout><LoadingComponent/></PageLayout>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <PageLayout>
      <Row>
      <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">All Tour</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">All Tour</li>
            </ul>
          </div>
        </div>
      </Col>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t("All Tours")}</h4>
              <Button
                variant="primary"
                onClick={addTour}
              >
                {t("Add Tour")}
              </Button>
              {/* <h4 className="mc-card-title" onClick={addTour}>{t("Add Tour")}</h4> */}
            </div>
            <TourTableComponent
              tours={tours}
              onEditClick={handleEditClick}
              onToggleActive={handleToggleActive}
            />
            <PaginationComponent 
              pageCount={pageCount} 
              handlePageClick={handlePageClick} 
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
