/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TranslatorContext } from "../../context/Translator";
import { Row, Col, Button } from "react-bootstrap";
import { LoadingComponent, PaginationComponent } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import Api from "../../api/baseUrl";
import { useNavigate, Link } from "react-router-dom";
import { InstructorTableComponent } from "../../components/tables";

export default function Instructors() {
  const { t } = useContext(TranslatorContext);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10); // or any default page size you prefer
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchTeam = async (page) => {
      try {
        const response = await Api.getAllInstructor(page, pageSize);
        console.log(response.data.data,response,'response')
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        const data = response.data.data;
        const totalPages = response.data.totalPages;
        setTeam(data || []); // Ensure `items` is an array
        // Calculate total pages based on total count
        setPageCount(totalPages)
        
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeam(currentPage);
  }, [currentPage, pageSize]);

  const thead = ["Name"];

  const addTeam = () => {
    navigate(`/addinstructor`);
  }

  const handlePageChange = (data) => {
    console.log(data)
    setCurrentPage(data.selected);
  };

  if (loading) {
    return (
      <PageLayout>
        <LoadingComponent />
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div>{t('Error:')} {error.message}</div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
       <Col xl={12}>
        <div className="mc-card mb-4">
          <div className="mc-breadcrumb">
            <h3 className="mc-breadcrumb-title">All Instructors</h3>
            <ul className="mc-breadcrumb-list">
              <li className="mc-breadcrumb-item">
                <Link to="/dashboard" className="mc-breadcrumb-link">Home</Link>
              </li>
              <li className="mc-breadcrumb-item">All Instructors</li>
            </ul>
          </div>
        </div>
      </Col>
      <Row>
        <Col xl={12}>
          <div className="mc-card">
            <div className="mc-card-header">
              <h4 className="mc-card-title">{t('Instructors')}</h4>
              <Button variant="primary" onClick={addTeam}>{`Add Instructor`}</Button>
            </div>
            <InstructorTableComponent thead={thead} tbody={team} />
            <PaginationComponent
             pageCount={pageCount}
             handlePageClick={handlePageChange}
             forcePage={currentPage}
            />
          </div>
        </Col>
      </Row>
    </PageLayout>
  );
}
