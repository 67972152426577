
export { default as EcommercePage } from "./EcommercePage";
export { default as MessagePage } from "./MessagePage";
export { default as NotificationPage } from "./NotificationPage";
export { default as AddSliderPage} from "./AddSliderPage";
export {default as AllSlider} from "./AllSlider";
export {default as AboutSection } from "./AboutSection";
export {default as Testimonials } from "./Testimonials";
export {default as AddTestimonials } from "./AddTestimonials";
export {default as ClientsPage } from "./ClientsPage";
export {default as AddClient } from "./AddClient";
export { default as ToursPage } from "./ToursPage";
export { default as AddTour }  from "./AddTour";
export { default as AllTours } from "./AllTours";
export { default as AllInquiry } from "./AllInquiry"
export { default as InquiryViewPage } from "./InquiryViewPage";
export { default as Team} from "./Team";
export { default as EditTeam} from "./EditTeam";
export { default as AddTeamMember} from "./AddTeamMember";
export { default as AllBlogs} from "./AllBlogs";
export { default as AddBlogPage } from "./AddBlogPage";
export { default as BlogPage } from "./BlogPage";
export { default as EditSlider } from "./EditSlider";
export { default as GalleryPage } from "./GalleryPage";
export { default as addInstructor } from "./AddInstructor"
export { default as Instructors } from "./Instructors";
export { default as EditInstructor } from "./EditInstructor";
export { default as InternationalPage } from "./InternationalPage";
export { default as DomesticPage } from './DomesticPage'
export { default as In_SchoolPage } from "./In_SchoolPage";
export { default as TrekkingPage } from './TrekkingPage';
export { default as AddReview } from './AddReview.js'
export { default as AllReview } from './AllReview.js'; 
export { default as FaqsPage } from './FaqsPage.js';
export { default as CurriculamPage } from './CurriculamPage.js';
export { default as IbProgramPage } from './IbProgramPage.js';
export { default as IaypProgramPage } from './IaypProgramPage.js';
export { default as CbseProgramPage } from './CbseProgramPage.js';
export { default as EditFaqPage } from './EditFaqPage.js';