import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Alert, Row, Col } from "react-bootstrap";
import Api from "../../api/baseUrl";
import PageLayout from "../../layouts/PageLayout";
import { ImageUrl } from "../../components/fields";
import { Link } from "react-router-dom";

const EditSlider = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [sliderData, setSliderData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [allowedFileType, setAllowedFileType] = useState(""); // To store allowed file type

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await Api.getSliderById(id);
        const data = response.data;
        setSliderData(data);

        // Determine the media type from the API response
        setAllowedFileType(data.mediaType || ""); // Assuming `mediaType` is directly available
      } catch (error) {
        console.error("Error fetching slider data:", error);
        setError("Error fetching slider data");
      }
    };

    fetchSliderData();
  }, [id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = file.type.startsWith("image") ? "image" : file.type.startsWith("video") ? "video" : "";
    
    if (allowedFileType === "image" && fileType === "image") {
      setSelectedFile(file);
      setError("");
    } else if (allowedFileType === "video" && fileType === "video") {
      setSelectedFile(file);
      setError("");
    } else {
      setSelectedFile(null);
      setError(`Invalid file type. Please upload a ${allowedFileType}.`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsEditing(true);

    const formData = new FormData();
    formData.append("title", sliderData.title);
    formData.append('mediaType', sliderData.mediaType);
    formData.append("description", sliderData.description);

    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      const response = await Api.updateSliderById(formData, id);

      if (!response.data) {
        throw new Error("Network response was not ok");
      }

      navigate('/slider');
    } catch (error) {
      console.error("Error updating slider:", error);
      setError("Error updating slider");
    } finally {
      setIsEditing(false);
    }
  };

  const file = ImageUrl(sliderData.optimizeUrl);

  return (
    <PageLayout>
      <div className="container">
        <Row>
          <Col xl={12}>
            <div className="mc-card mb-4">
              <div className="mc-breadcrumb">
                <h3 className="mc-breadcrumb-title">Edit Slider</h3>
                <ul className="mc-breadcrumb-list">
                  <li className="mc-breadcrumb-item">
                    <Link to="#" className="mc-breadcrumb-link">Home</Link>
                  </li>
                  <li className="mc-breadcrumb-item">
                    <Link to="#" className="mc-breadcrumb-link">Products</Link>
                  </li>
                  <li className="mc-breadcrumb-item">Edit Slider</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={sliderData.title || ""}
                  onChange={(e) =>
                    setSliderData({ ...sliderData, title: e.target.value })
                  }
                />
              </Form.Group>
              {/* Uncomment if needed
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={sliderData.description || ""}
                  onChange={(e) =>
                    setSliderData({ ...sliderData, description: e.target.value })
                  }
                />
              </Form.Group>
              */}
              
            </Form>
          </Col>
          <Col xl={6}>
            <div className="mt-3">
              <h5>Preview:</h5>
              {file && (
                allowedFileType === "image" ? (
                  <img
                    src={file}
                    alt={sliderData.title}
                    style={{ maxHeight: "300px", maxWidth: "80%" }}
                  />
                ) : allowedFileType === "video" ? (
                  <video
                    src={file}
                    controls
                    style={{ maxHeight: "300px", maxWidth: "80%" }}
                  />
                ) : (
                  <p>Unsupported media type</p>
                )
              )}
              <Form.Group controlId="formFile">
                <Form.Label>Upload File</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            </div>
            <Button variant="primary" className="mt-3" type="submit" disabled={isEditing}>
                {isEditing ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Save Changes"
                )}
              </Button>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default EditSlider;
